import React from 'react'

const SvgCg01 = props => (
  <svg id='cg-01_svg___\u56FE\u5C42_1' data-name='\u56FE\u5C42 1' viewBox='0 0 25 25' {...props}>
    <defs>
      <style>{'.cg-01_svg__cls-1{fill:#12cc75;stroke-width:0}'}</style>
    </defs>
    <path
      className='cg-01_svg__cls-1'
      d='M6.2 25c-.2 0-.3 0-.5-.1-.3-.2-.5-.5-.5-.9v-2.5H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h23c.6 0 1 .4 1 1v19.5c0 .6-.4 1-1 1H12.2l-5.5 3.3c-.2 0-.3.1-.5.1zM2 19.5h4.2c.6 0 1 .4 1 1v1.7l4.2-2.5c.2 0 .3-.1.5-.1H23V2H2v17.5z'
    />
    <path
      className='cg-01_svg__cls-1'
      d='M11.8 8.4l-1.4.8c-.5-.7-1.2-1.1-2.1-1.1-1.5 0-2.5 1.1-2.5 2.6 0 1.6 1.1 2.6 2.5 2.6s1.6-.4 2.1-1.1l1.3.9c-.7 1-1.9 1.7-3.4 1.7-2.5 0-4.2-1.8-4.2-4.2s1.7-4.2 4.2-4.2 2.8.7 3.4 1.8zM19.9 7.9L18.7 9c-.5-.5-1.1-.8-1.9-.8-1.5 0-2.5 1.1-2.5 2.6s1.1 2.7 2.5 2.7 2.1-.6 2.3-1.8h-2v-1.3h3.7v.5c0 2.4-1.6 4.2-3.9 4.2s-4.2-1.6-4.2-4.2 1.8-4.2 4.2-4.2 2.3.5 3.1 1.2z'
    />
  </svg>
)

export default SvgCg01
