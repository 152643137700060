import React from 'react'

const SvgProduct02 = props => (
  <svg viewBox='0 0 24 24' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 22a10 10 0 1110-10 10 10 0 01-10 10zm-.14-14.49a2.44 2.44 0 012.61 2.28.23.23 0 00.21.21h1.5c.18 0 .23-.06.23-.13 0-2.38-1.91-4-4.56-4-3.07 0-4.85 2-4.85 5.4v1.43C7 16 8.78 18 11.85 18c2.64 0 4.56-1.62 4.56-3.88a.13.13 0 00-.12-.13h-1.61a.23.23 0 00-.22.21 2.38 2.38 0 01-2.6 2.12C10 16.33 9 15 9 12.67v-1.44c0-2.39 1-3.72 2.86-3.72z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgProduct02
