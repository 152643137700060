import styled from 'styled-components'
import { hideSMDown } from '../../theme/mixin'
import { color, spacing, typography } from '../../theme/variables'
import Flex from '../flex'
export const ContactbarContainer = styled.div`
  cursor: pointer;
  position: fixed;
  z-index: 10;
  top: 60%;
  right: 20px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 40px 0;
  height: ${p => (p.isSupport ? '350px' : '300px')};
  background-color: rgba(10, 10, 10, 0.7);
  ${hideSMDown};
`

export const PopoverWrapper = styled.div`
  background: rgba(31, 196, 98, 0.8);
  position: absolute;
  top: 18px;
  left: -10px;
  transform: translate(-100%, -50%);
  border-radius: 6px;
  color: white;
  box-shadow: 0px 4px 10px 0px rgba(17, 203, 117, 0.46);
  display: none;
  white-space: nowrap;
  &.common-popover-style {
    font-size: 14px;
    color: #fff;
    width: 100px;
    text-align: center;
    line-height: 34px;
  }
  &.qr-popover-style {
    height: 40px;
    padding: 6px 0 5px;
    .qr-title {
      width: 140px;
      font-size: 12px;
      text-align: center;
      line-height: 14px;
    }
    .qr-phone-number {
      width: 140px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 14px;
    }
  }
  &.wechat-popover-style {
    width: 140px;
    height: 200px;
    text-align: center;
    line-height: 200px;
  }
  a {
    color: #fff;
  }
`
export const ContactItem = styled.li`
  list-style: none;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  svg[data-icon-hover] {
    display: none;
    background: #222;
  }
  :hover {
    svg[data-icon] {
      display: none;
    }
    svg[data-icon-hover] {
      display: unset;
    }
    > svg {
      color: ${color.primary};
    }
    ${PopoverWrapper} {
      display: block;
    }
  }
`
export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    display: block;
  }
  :hover {
    background: #222222;
    border-radius: 4px;
  }
`
export const PopoverContainer = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  left:-250%;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border-radius: 4px;
  font-size: ${typography.textSmall};
  padding: 0 5px;
  // :before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 100%;
  //   width: 20px;
  //   height: 100%;
  // }
  // :after {
  //   content: '';
  //   z-index: 0;
  //   border-bottom: ${spacing.small} solid transparent;
  //   border-left: ${spacing.small} solid rgba(0, 0, 0, 0.5);
  //   border-top: ${spacing.small} solid transparent;
  //   position: absolute;
  //   right: -10px;
  //   top: 45%;
  // }
`

export const PopoverItem = styled.div`
  background: #13cb75;
  border-radius: 6px;
  padding: 9px 22px;
  color: white;
  box-shadow: 0px 4px 10px 0px rgba(17, 203, 117, 0.46);
  a {
    color: white;
    font-size: 14px;
  }
`

export const PopoverItemWrapper = styled.a`
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  span {
    color: white;
  }
  svg {
    margin-bottom: ${spacing.small};
  }
  padding: ${spacing.small} 0;
  :hover {
    svg {
      color: ${color.primary};
    }
    color: ${color.primary};
  }
`

export const InputStyle = `
  flex-grow:1;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
`
export const Form = styled.form`
  max-width: 630px;
  width: 100%;
`
export const InputRow = styled.div`
  .error {
    font-size: 12px;
    color: red;
  }
  .file-list {
    font-size: 14px;
    margin-top: 7px;
    color: #999;
    display: flex;
    justify-content: space-between;
    .file-remove-icon {
      .close-wrap {
        width: 20px;
      }
    }
  }
  .upload-tip {
    font-size: 14px;
    color: #999;
    margin-left: 10px;
  }
`

// export const InputRow = styled(Flex)`
//   margin: 10px 0;
//   font-size: ${typography.textSmall};
//   flex-direction: column;
//   // align-items: flex-start;
//   label {
//     margin: 0 1em;
//     line-height: 30px;
//     font-weight: bold;
//     color: #666;
//     &.form-label-required {
//       &::before {
//         content: '*';
//         color: #ea4949;
//       }
//     }
//   }
//   input {
//     ${InputStyle};
//   }
//   textarea {
//     height: 120px;
//     ${InputStyle};
//   }
// `
export const FeedbackHeader = styled.div`
  height: 40px;
  background: #333;
  display: flex;
  justify-content: space-between;
`
export const FeedbackTitle = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
`
export const ModalWrapper = styled.div`
  width: 610px;
`
export const FbCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #666666;
    border-radius: 3px;
    display: inline-block;
  }
  span,
  label {
    flex-grow: 1;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
`
export const FbCheckboxInput = styled.span`
  display: inline-block;
  margin-right: 7px;
`
export const FbFormLabel = styled.div`
  font-size: 14px;
  &::before {
    color: #ea4949;
    content: '*';
  }
  margin-bottom: 15px;
`
export const FbFormField = styled.div`
  position: relative;
  .checkbox-container {
    width: 100%;
    font-size: 14px;
    margin-bottom: 15px;
    div {
      display: inline-block;
    }
    div:not(:last-child) {
      width: 160px;
    }
  }
  .textarea-desc {
    width: 630px;
    height: 150px;
    border: 1px solid #999999;
    padding: 18px 20px;
    &:focus {
      border-color: #13cb75;
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      color: #999;
    }
  }
  .input-count {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #666;
    font-size: 14px;
  }
`
export const UploadBtn = styled.button`
  width: 117px;
  height: 36px;
  border: 1px solid #999999;
  color: #666666;
  line-height: 20px;
  vertical-align: middle;
  span {
    font-size: 14px;
  }
  .plus-icon {
    font-size: 24px;
    margin-right: 10px;
    font-weight: bold;
  }
`
export const DialogFooter = styled(Flex)`
  justify-content: flex-end;
  margin-top: 24px;
`
export const CancelBtn = styled.button`
  color: #13cb75;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #13cb75;
  font-size: 14px;
  line-height: 31px;
  margin-right: 13px;
  padding: 0 25px;
  cursor: pointer;
`
export const SubmitBtn = styled(CancelBtn)`
  background: #13cb75;
  color: #fff;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')}pointer;
`
