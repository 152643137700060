import React from 'react'
import styled from 'styled-components'
import { transition } from '../../theme'
export const FadeItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition: ${transition('opacity')};
`

class FadeSwieprViews extends React.Component {
  constructor(props) {
    super(props)
    this.state = { indexLatest: props.index }
  }
  componentWillReceiveProps(nextProps) {
    const { index } = nextProps
    if (typeof index === 'number' && index !== this.props.index) {
      this.setState({
        indexLatest: index,
      })
    }
  }
  render() {
    const { children } = this.props
    const { indexLatest } = this.state
    return (
      <div>
        {React.Children.map(children, (child, indexChild) => {
          return <FadeItem show={indexChild === indexLatest}>{child}</FadeItem>
        })}
      </div>
    )
  }
}

export default FadeSwieprViews
