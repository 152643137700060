class SessionStorage {
  constructor(storage) {
    if (!storage) return
    this.storage = storage
  }
  get(key) {
    const value = this.storage.getItem(key)
    return JSON.parse(value)
  }

  set(key, value) {
    if (!value) return
    this.storage.setItem(key, JSON.stringify(value))
  }
  remove(key) {
    this.storage.removeItem(key)
  }
}

function createSessionStorage() {
  if (typeof window !== 'undefined') {
    return new SessionStorage(window.sessionStorage)
  }
  return new SessionStorage()
}

export const sessionStorage = createSessionStorage()
