import styled from 'styled-components'
import { lighten } from 'polished'
import Flex from '../flex'
import { H2, H3 } from '../atoms'
import { typography, color, multiLineTextEllipsis, textEllipsis, Media, spacing } from '../../theme'

const greenText = '瑞云热点'

export const SideBarTitle = styled(H2)`
  padding: 0 ${spacing.small};
  margin: 1rem 0;
  border-left: 4px solid ${color.primary};
  font-size: ${typography.h4};
  color: ${lighten(0.2, color.panel)};
`
export const QaPostContainer = styled.div`
  color: ${lighten(0.2, color.panel)};
  margin-bottom: 50px;
  max-width: 750px;
  a {
    color: ${color.primary};
  }
  div,
  p,
  span {
    line-height: 2;
  }
  p,
  span,
  img,
  iframe {
    margin: ${spacing.base} 0;
  }
  img,
  video,
  iframe {
    display: block;
    max-width: 100%;
  }
  table {
    margin: 1em 0;
    width: 100%;
    overflow: hidden;
    border: 0;
  }
  tr {
    border: 1px solid ${color.text};
  }
  td {
    word-wrap: break-word;
    max-width: 7em;
    border: 1px solid ${color.highlight};
    padding: 5px;
  }
  th {
    border: 1px solid ${color.primary};
    background-color: ${color.secondary};
    color: #fff;
    padding: 1em;
  }
  ul,
  ol {
    padding-left: 20px;
  }
  *.center {
    text-align: center;
  }
  *.bold {
    font-weight: bold;
  }
  *.img-source {
    text-align: center;
    color: ${color.prompt};
    font-size: ${typography.textSmall};
    margin: 0;
  }
`
export const QaPostTitle = styled(H3)`
  margin-top: 20px;
  color: ${color.panel};
  font-weight: 500;
`
export const QaPostMsg = styled(Flex)`
  justify-content: flex-start;
  color: ${color.prompt};
  margin: 10px 0 20px;
  font-size: ${typography.textSmall};
  ${textEllipsis}
  span {
    margin-right: ${spacing.small};
  }
  svg {
    margin-right: 5px;
  }
  div {
    margin-right: ${spacing.base};
  }
`
export const QaLinkToPost = styled.p`
  color: #666;
  font-size: ${typography.textSmall};
  margin-bottom: ${spacing.small};
  a {
    color: #666;
  }
  :hover,
  :hover a {
    color: ${color.primary};
  }
`
export const PostCardContainer = styled(Flex)`
  position: relative;
  padding: 1rem;
  margin: 0.5rem 0;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-bottom: 1px solid #ccc;
  margin: 0 20px;
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
  }
`

export const PostCardImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${color.primary};
  }
`
export const PostCardContent = styled(Flex)`
  max-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin: 1.5rem;
  ${Media.lessThan(Media.small)} {
    margin: 1rem 0;
  }
`
export const PostCardTitle = styled.div`
  display: flex;
  align-items: center;
  a {
    color: ${color.panel};
    font-weight: 600;
    &:hover {
      color: ${color.primary};
    }
  }
  p {
    margin: 0;
  }
  strong {
    color: ${color.primary};
  }
  font-size: ${typography.h4};
`
export const PostCardIntro = styled.div`
  font-size: ${typography.textSmall};
  color: ${color.prompt};
  margin: 1rem 0;
  ${multiLineTextEllipsis(2)}
  p {
    margin: 0;
  }
  strong {
    color: ${color.primary};
  }
  pre,
  code {
    overflow: auto;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`
export const PostCardDate = styled.span`
  svg {
    margin-right: 5px;
  }
`
export const PostCardMsg = styled(Flex)`
  max-width: 100%;
  justify-content: flex-start;
  font-size: ${typography.textSmall};
  color: ${color.prompt};
  span {
    margin-right: ${spacing.base};
    ${textEllipsis}
  }
`
export const PostCardCategory = styled.div`
  position: absolute;
  top: 1.55rem;
  left: 1.25rem;
  padding: 5px 10px;
  background: ${p => (p.category === greenText ? color.primary : '#373737')};
  color: white;
  font-size: ${typography.textThin};
`
