import * as React from 'react'
const SVGComponent = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    // xmlns:xlink='http://www.w3.org/1999/xlink'
    width='24px'
    height='24px'
    {...props}
  >
    <path
      fillRule='evenodd'
      fill='rgb(12, 123, 71)'
      d='M5.494,17.659 C5.24,17.659 4.554,17.184 4.554,16.709 C4.554,16.234 5.24,15.759 5.494,15.759 C5.963,15.759 6.433,16.234 6.433,16.709 C6.433,17.184 5.963,17.659 5.494,17.659 M8.311,10.996 C4.85,10.996 0.798,13.687 0.798,17.12 C0.798,18.912 1.894,20.653 3.615,21.761 C3.928,21.920 2.989,23.819 3.302,23.978 C3.317,23.985 3.336,23.989 3.358,23.989 C3.563,23.989 4.59,23.667 4.554,23.344 C5.50,23.22 5.545,22.700 5.751,22.700 C5.773,22.700 5.792,22.704 5.807,22.711 C6.589,22.870 7.372,23.28 8.311,23.28 C12.536,23.28 15.823,20.337 15.823,17.12 C15.823,13.687 12.536,10.996 8.311,10.996 '
    />
    <path
      fillRule='evenodd'
      fill='rgb(255, 255, 255)'
      d='M23.896,8.317 C23.896,3.681 19.379,0.0 13.903,0.0 C8.427,0.0 3.910,3.681 3.910,8.317 C3.910,12.953 8.427,16.634 13.903,16.634 C14.998,16.634 16.93,16.497 17.51,16.225 C17.462,16.88 20.200,18.133 20.611,17.997 C21.158,17.861 19.379,15.407 19.789,15.134 C22.253,13.498 23.896,11.44 23.896,8.317 ZM9.836,8.216 C9.160,8.216 8.485,7.594 8.485,6.971 C8.485,6.349 9.160,5.726 9.836,5.726 C10.511,5.726 11.186,6.349 11.186,6.971 C11.186,7.594 10.511,8.216 9.836,8.216 ZM18.481,8.216 C17.806,8.216 17.131,7.594 17.131,6.971 C17.131,6.349 17.806,5.726 18.481,5.726 C19.157,5.726 19.832,6.349 19.832,6.971 C19.832,7.594 19.157,8.216 18.481,8.216 Z'
    />
  </svg>
)
export default SVGComponent
