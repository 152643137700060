import React from 'react'

export default React.memo(function WebpImg({
  src = '',
  alt = '',
  width = 1000,
  quality = 75,
  otherStyle = {},
  className = '',
}) {
  return (
    <picture>
      <source type='image/webp' srcSet={`${src}?w=${width}&fm=webp&q=${quality}`} />
      <source srcSet={`${src}?w=${width}&q=${quality}`} />
      <img
        srcSet={`${src}?w=${width}&q=${quality}`}
        src={`${src}?w=${width}&q=${quality}`}
        alt={alt}
        loading='lazy'
        className={className}
        {...otherStyle}
      />
    </picture>
  )
})
