import React from 'react'

const SvgProduct01 = props => (
  <svg viewBox='0 0 24 24' {...props}>
    <defs>
      <style>{'.product-01_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='product-01_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='product-01_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='product-01_svg__cls-1'
          d='M10.7 23l11.8-4.5h-13l1.2 4.5zM24 16.6L23.3 4l-4 12.3 4.7.3zM21 3.2L8.8 0l10.5 7.6L21 3.2zM6.8 1.4L0 12l10.5-7.6-3.7-3zM10.5 4.3v.1-.1zM.8 14.2L8.7 24l-4-12.3-3.9 2.5z'
        />
      </g>
    </g>
  </svg>
)

export default SvgProduct01
