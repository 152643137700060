import { Modal } from '@renderbus/common/components'
import { API, Axios } from '@renderbus/common/utils'
import React from 'react'
import Close from '../modal/close'
import {
  CancelBtn,
  DialogFooter,
  FbCheckbox,
  FbCheckboxInput,
  FbFormField,
  FbFormLabel,
  Form,
  InputRow,
  ModalWrapper,
  SubmitBtn,
  UploadBtn,
} from './atoms'
import checkedPng from './images/checked.png'
import { UserAuthorizationService } from '../../service'

const problemTypes_1 = [
  { label: '内容找不到', value: 7 },
  { label: '内容没更新', value: 8 },
  { label: '内容侵权或违法问题', value: 9 },
]
const problemTypes_2 = [
  { label: '内容描述不清楚', value: 10 },
  { label: '链接有错误', value: 11 },
  { label: '步骤不完整', value: 12 },
  { label: '代码/图片丢失', value: 13 },
]

const allowedFileTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'application/msword',
  'application/pdf',
]
const maxFileSize = 5 * 1024 * 1024 // 5MB
const descError = '请输入描述'
const problemError = '请选择问题分类'

const FeedbackModal = ({ visible = false, onClose, onThanksChange }) => {
  const isWhite = str => !/^.*\S+.*$/.test(str)
  const [checked, setChecked] = React.useState([])
  const inputRef = React.useRef(null)
  const [uploadedFile, setUploadedFile] = React.useState(null)
  const [error, setError] = React.useState('')
  const [desc, setDesc] = React.useState('')
  const instance = new Axios(API.auth.host)
  const [formError, setFormError] = React.useState({ checked: '', desc: '' })
  const [flag, setFlag] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const onCheckboxChange = value => {
    setChecked(
      checked.includes(value) ? checked.filter(item => item !== value) : [...checked, value],
    )
  }

  const onUpload = () => {
    const formData = new FormData()
    formData.append('file', uploadedFile)
    return instance.post(API.event.uploadScreenshot, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  }

  const onSelectFile = e => {
    const file = e.target.files[0]
    if (!file) return
    const error = '支持格式.png .jpg.gif.jpeg.pdf.doc ，不超过5MB'
    if (!allowedFileTypes.includes(file.type)) {
      setError(error)
    } else if (file.size > maxFileSize) {
      setError(error)
    } else if (uploadedFile) {
      setError('只能上传一个文件')
    } else {
      setUploadedFile(file)
      setError('')
    }
  }

  const onUploadSubmit = e => {
    e.preventDefault()
    inputRef.current.click()
  }

  const handleSubmit = async () => {
    if (onInValidate()) return
    setLoading(true)
    let screenshot = ''
    if (uploadedFile) {
      const res = await onUpload()
      screenshot = res.data
    }
    instance
      .post(API.event.addFeedback, {
        reasonList: checked,
        description: desc,
        screenshot,
        userId: UserAuthorizationService.getUserId() || '',
      })
      .then(res => {
        setLoading(false)
        if (res.result) {
          onModalClose()
          onThanksChange(true)
          setTimeout(() => {
            onThanksChange(false)
          }, 3000)
        }
      })
  }
  const onInValidate = () => {
    setFlag(true)
    setFormError({
      desc: !desc || isWhite(desc) ? descError : '',
      checked: !checked.length ? problemError : '',
    })
    return !(checked.length && desc && !isWhite(desc))
  }
  const onDescChange = value => setDesc(value)

  const onModalClose = () => {
    onClose()
    setChecked([])
    setError('')
    setDesc('')
    setFlag(false)
    setFormError({ checked: '', desc: '' })
    setUploadedFile(null)
  }
  const onFileRemove = () => {
    setUploadedFile(null)
  }
  React.useEffect(() => {
    if (flag)
      setFormError({
        desc: !desc || isWhite(desc) ? descError : '',
        checked: !checked.length ? problemError : '',
      })
  }, [checked.length, desc, flag])

  return (
    <>
      <ModalWrapper>
        <Modal baseStyle visible={visible} title='我要反馈' onClose={onModalClose}>
          <Form onSubmit={handleSubmit}>
            <InputRow>
              <FbFormLabel htmlFor='comment-input' className='form-label-required'>
                问题分类
              </FbFormLabel>
              <FbFormField>
                <div className='checkbox-container'>
                  {problemTypes_1.map(item => {
                    return (
                      <FbCheckbox key={item.value}>
                        <FbCheckboxInput onClick={() => onCheckboxChange(item.value)}>
                          {checked.includes(item.value) ? (
                            <img src={checkedPng} alt='' />
                          ) : (
                            <span className='checkbox'></span>
                          )}
                        </FbCheckboxInput>
                        <label>{item.label}</label>
                      </FbCheckbox>
                    )
                  })}
                </div>
                <div className='checkbox-container' style={{ marginBottom: '8px' }}>
                  {problemTypes_2.map(item => {
                    return (
                      <FbCheckbox key={item.value}>
                        <FbCheckboxInput onClick={() => onCheckboxChange(item.value)}>
                          {checked.includes(item.value) ? (
                            <img src={checkedPng} alt='' />
                          ) : (
                            <span className='checkbox'></span>
                          )}
                        </FbCheckboxInput>
                        <label>{item.label}</label>
                      </FbCheckbox>
                    )
                  })}
                </div>
              </FbFormField>
              {formError.checked && <div className='error'>{formError.checked}</div>}
            </InputRow>
            <InputRow style={{ marginTop: '30px' }}>
              <FbFormLabel>描述</FbFormLabel>
              <FbFormField>
                <textarea
                  className='textarea-desc'
                  placeholder='建议您详细描述遇到问题的场景、问题细节、期望解决的办法或者需求建议，方便 我们更好地优化和解决问题'
                  value={desc}
                  onChange={event => onDescChange(event.target.value)}
                  maxLength={300}
                />
                <div className='input-count'>{desc.length}/300</div>
              </FbFormField>
              {formError.desc && <div className='error'>{formError.desc}</div>}
            </InputRow>
            <InputRow style={{ marginTop: '16px' }}>
              <UploadBtn onClick={onUploadSubmit}>
                <span className='plus-icon'>+</span>
                <span>上传附件</span>
              </UploadBtn>
              <span className='upload-tip'>支持格式.png .jpg.gif.jpeg.pdf.doc ，不超过5MB</span>
              <input
                ref={inputRef}
                type='file'
                onChange={onSelectFile}
                accept={allowedFileTypes.join(',')}
                multiple={false}
                style={{ display: 'none' }}
              />
              {uploadedFile && (
                <div className='file-list'>
                  <div>{uploadedFile.name}</div>
                  <div className='file-remove-icon'>
                    <Close onClick={onFileRemove} />
                  </div>
                </div>
              )}
              <div className='error'>{error}</div>
            </InputRow>
          </Form>
          <DialogFooter>
            <CancelBtn onClick={onModalClose}>取消</CancelBtn>
            <SubmitBtn onClick={handleSubmit} disabled={loading}>
              提交
            </SubmitBtn>
          </DialogFooter>
        </Modal>
      </ModalWrapper>
    </>
  )
}

export default FeedbackModal
