export function getDateFormatYMDH(dateString) {
  const date = new Date(dateString)
  return `${[date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/')}-${date
    .getHours()
    .toString()
    .padStart(2, '0')}h`
}
export function getDateFormatYMD(dateString) {
  const date = new Date(dateString)
  return `${[
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date
      .getDate()
      .toString()
      .padStart(2, '0'),
  ].join('-')}`
}
export function getDateFormatYMDHMS(dateString) {
  const date = new Date(dateString)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0')
  const hour = date
    .getHours()
    .toString()
    .padStart(2, '0')
  const minute = date
    .getMinutes()
    .toString()
    .padStart(2, '0')
  const second = date
    .getSeconds()
    .toString()
    .padStart(2, '0')
  return `${[date.getFullYear(), month, day].join('-')} ${[hour, minute, second].join(':')}`
}
