const contentfulCoverImgPattern = '!\\[.*\\]\\((.*?)\\)'
const paragraphPattern = /<p>(.*?)<\/p>/g
const emptyParagraphRegex = /<p>(?:\s*|<[^>]*>)*<\/p>/
// /<p>(\s*|&nbsp;|\n)*<\/p>/
export default class CmsContentService {
  static getCoverImg(content, tinyMceContent) {
    const databaseCoverImgPattern = '<img[\\s\\S]*?src="(.*?)"'
    const imgCoverRegex = new RegExp(`(${contentfulCoverImgPattern}|${databaseCoverImgPattern})`)
    const matchResult = (content || tinyMceContent).match(imgCoverRegex)
    if (matchResult) {
      return matchResult[2] || matchResult[3]
    }
    return ''
  }
  static removeCoverImg(content, tinyMceContent) {
    const databaseCoverImgPattern = '<img[\\s\\S]*?>'
    const imgCoverRegex = new RegExp(`(${contentfulCoverImgPattern}|${databaseCoverImgPattern})`)
    const replaceResult = (content || tinyMceContent).replace(imgCoverRegex, '')
    return replaceResult || ''
  }
  static getAbstract(content, tinyMceContent) {
    const text = CmsContentService.getMainText(content, tinyMceContent)
    return text
      .replace(
        /(&rdquo;|&ldquo;|&nbsp;|&mdash;|&middot;|\r|\n|#|\*\*|---|__|<.*?>|!?\[.*\]\(.*?\)!?|(https?:\/\/)([a-zA-Z0-9./-])*)/g,
        '',
      )
      .substring(0, 170)
  }

  static getMainText(content, tinyMceContent) {
    const contentfulTextPattern = '!\\[.*\\)([\\s\\S\\r\\n]*)'
    const databaseTextPattern = '<img.*\\/>([\\s\\S\\r\\n]*)'
    const tinyMCECoverImgPattern = /<p><img.*<\/p>/i
    const textRegex = new RegExp(`(${contentfulTextPattern}|${databaseTextPattern})`)
    const matchResult = (content || tinyMceContent).match(textRegex)
    if (!content && tinyMceContent) {
      return tinyMceContent.replace(tinyMCECoverImgPattern, '')
    }
    if (matchResult) {
      return matchResult[0]
    }
    return content || tinyMceContent
  }
  static getFirstParagraph(content, tinyMceContent) {
    const matchParagraph = (content || tinyMceContent).match(paragraphPattern)
    if (matchParagraph) {
      const match = matchParagraph.filter(item => !emptyParagraphRegex.test(item))
      return match[0]
    }
    // return content || tinyMceContent
    return ''
  }
  static removeFirstParagraph(content, tinyMceContent) {
    // p标签为空正则

    let flag = false,
      firstP = false

    const replaceResult = (content || tinyMceContent).replace(paragraphPattern, match => {
      if (!flag && emptyParagraphRegex.test(match)) {
        return ''
      }
      if (!emptyParagraphRegex.test(match)) {
        flag = true
        if (!firstP) {
          firstP = true
          return ''
        }
      }
      return match // 保留其他<p>标签
    })
    return replaceResult || ''
  }
}
