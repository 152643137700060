import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import Wrapper from '../wrapper'
import Media from '../../theme/media'
import { hideSMDown, hideSMUp, textEllipsis } from '../../theme/mixin'
import transition from '../../theme/transition'
import Flex from '../flex'
import { color, typography, spacing } from '../../theme/variables'
import { shadows } from '../../theme'

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.divider};
  .hide-at-mobile {
    ${hideSMDown};
  }
  .show-at-mobile {
    ${hideSMUp};
  }
  border: none;
  ${Media.lessThan(Media.small)} {
    justify-content: space-between;
    padding: 0 ${spacing.base};
    max-width: 100%;
  }
`
export const Navbar = styled.header`
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  min-width: 1440px;
  background-color: ${color.panel};
  ${Media.lessThan(Media.small)} {
    min-width: initial;
  }
`
export const Brand = styled.a`
  cursor: pointer;
  width: 143px;
  display: inline-block;
  margin-right: ${spacing.base};
  svg {
    height: 32px;
  }
`
const commonLink = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 0 ${spacing.base};
  text-decoration: none;
  font-size: ${typography.textSmall};
  color: ${p => (p.isActive ? color.primary : 'white')};
  transition: ${transition(['color', 'background-color'])};
  :hover {
    color: ${color.primary};
  }
`
export const NavAnchor = styled.a`
  ${commonLink}
`
/**
 * @see https://github.com/styled-components/styled-components/issues/135#issuecomment-256018643
 */
export const NavLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  ${commonLink}
`
export const MenuIcon = styled.i`
  display: ${p => (p.isActive ? 'block' : 'none')};
  position: relative;
  font-style: normal;
  color: white;
  width: 100%;
  height: 2px;
  top: 0.75em;
  background-color: currentColor;
  ::before,
  ::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
  }
  ::before {
    top: -5px;
  }
  ::after {
    top: 5px;
  }
`
export const CloseIcon = styled.i`
  display: ${p => (p.isActive ? 'block' : 'none')};
  position: relative;
  font-style: normal;
  color: white;
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  height: 100%;
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0.65em;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: currentColor;
  }
  ::before {
    transform: rotate(-45deg);
  }
  ::after {
    transform: rotate(45deg);
  }
`
export const ArrowIcon = styled.i`
  display: block;
  font-style: normal;
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
  ${p => p.active && 'transform: rotate(180deg)'};
  :before,
  :after {
    content: '';
    pointer-events: none;
    height: 2px;
    width: 1em;
    top: 0.75em;
    position: absolute;
    background-color: currentColor;
    border-radius: 1px;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
    left: 0.65em;
  }
`
export const IconWrap = styled.div`
  width: 1.5em;
  height: 1.5em;
  margin-left: 1em;
  transition: ${transition('transform')};
`
export const MobileMenuBlock = styled(Flex)`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: ${transition('max-height')};
  max-height: ${props => (props.isExpand ? '640px' : '0px')};
  max-width: 100%;
  ${hideSMUp};
  > a {
    font-size: ${typography.text};
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    display: block;
    background-color: ${color.panel};
    padding-bottom: ${spacing.small};
    padding-left: ${spacing.large};
  }
  > a[aria-current='page'] {
    background-color: rgba(0, 0, 0, 0.3);
  }
`
export const MobileMenuContainer = styled(Flex)`
  top: 60px;
  left: 0;
  right: 0;
  position: absolute;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: ${color.lightCaculate};
  overflow: hidden;
  transition: ${transition('max-height')};
  max-height: ${props => (props.isExpand ? '720px' : '0px')};
  max-width: 100%;
  border-top: ${props => (props.isExpand ? '1px solid #444' : 'none')};
  ${hideSMUp};
  div {
    width: 100%;
    position: relative;
    span {
      color: ${color.white};
      padding: 0px 0px 0px ${spacing.base};
    }
    ${ArrowIcon} {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 55px;
      top: 10px;
      margin: auto 0px;
      line-height: 20px;
      vertical-align: top;
    }
  }
  > a {
    font-size: ${typography.text};
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    display: block;
    margin-bottom: ${spacing.small};
    background-color: ${color.lightCaculate};
  }
  > a[aria-current='page'] {
    background-color: rgba(0, 0, 0, 0.3);
  }
`
export const CollapsibleContent = styled.div`
  overflow: hidden;
  display: flex;
  transition: ${transition('max-height')};
  border-top: 1px solid ${color.divider};
  border-width: ${props => (props.isExpand ? '1px' : '0px')};
  max-height: ${props => (props.isExpand ? '40px' : '0px')};
  > a {
    height: 40px;
    line-height: 40px;
    font-size: ${typography.text};
  }
`
export const MenuItem = styled.div`
  color: white;
  padding: 0 20px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${typography.text};
  border-top: 1px solid ${color.divider};
`
export const DropdownWrap = styled.div`
  display: none;
  position: absolute;
  top: 60px;
  left: 50%;
  text-align: center;
  transform: translateX(-45%);
  box-shadow: ${shadows[2]};
  background-color: ${color.panel};
  border: 1px solid #666666;
  border-radius: 10px;
  overflow: hidden;
  a,
  span {
    width: 10em;
    height: 40px;
    line-height: 40px;
    display: block;
    background-color: ${color.panel};
    cursor: pointer;
    :hover {
      background-color: ${color.panel};
    }
  }
`
export const DropdownBlock = styled(Flex)`
  display: flex;
  width: 270px;
  height: 80px;
  border-radius: 10px;
  padding-top: 15px !important;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${props => (props.isActive ? '#404040' : color.panel)};
  &.cg-community .sub-text {
    letter-spacing: 1px;
  }
  .icon {
    margin-left: 15px;
    height: 25px;
    line-height: normal;
    * {
      width: 25px;
      height: 25px;
    }
  }
  .right-icon {
    margin-left: 15px;
    height: 46px;
    width: 46px;
    line-height: 46px;
  }
  .text {
    margin-left: 11px;
    p {
      color: #999999;
      height: 13px;
      line-height: 18px;
      margin: 0px 0px 0px 0px;
    }
    p:first-child {
      color: ${props => (props.isActive ? color.primary : '#FFFFFF')};
      margin-bottom: 10px;
      line-height: 13px;
    }

    p.is-picture {
      color: #19b2ff;
      font-size: 16px;
      margin-top: 6px;
    }
    p.is-animation {
      color: ${color.primary};
      font-size: 16px;
      margin-top: 6px;
    }
  }
  :hover {
    background-color: #404040;
    .text p:first-child {
      color: ${color.primary};
    }
    .text p.is-picture {
      color: #19b2ff;
    }
    .subtitle {
      color: white;
    }
  }
`
export const QrCodeBlock = styled(Flex)`
  align-items: center;
  border-top: 1px solid #505050;
  width: 100%;
  justify-content: flex-start;
  margin: 22px 0 0 0;
  padding-top: 22px;
  img {
    margin-right: 12px;
    width: 90px;
    height: 90px;
  }
  p {
    height: 20px;
    line-height: 20px;
    margin: 0;
  }
  .qrcode-text {
    color: #999999;
  }
`
export const DropdownBlockWrap = styled.div`
  display: none;
  width: 588px;
  padding: 22px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  overflow: hidden;
  top: 60px;
  left: 50%;
  transform: translateX(-45%);
  background-color: ${color.panel};
  border: 1px solid #666666;
  border-radius: 10px;
  box-shadow: ${shadows[2]};
  color: white;
`
export const DropdownMenu = styled.div`
  ${commonLink};
  cursor: default;
  :hover {
    ${DropdownWrap} {
      display: block;
    }
    ${DropdownBlockWrap} {
      display: flex;
    }
  }
`
export const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`
export const AccountDetail = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  a,
  span {
    color: white;
    font-size: ${typography.textSmall};
    :hover {
      color: ${color.primary};
    }
  }
  ${Media.lessThan(Media.small)} {
    > a {
      padding: 0;
      ${textEllipsis}
    }
  }
`
export const LoginButton = styled.div`
  ${hideSMDown}
`
export const LoginLink = styled.a`
  ${commonLink}
`
export const RegisterButton = styled(Flex)`
  ${Media.lessThan(Media.small)} {
    > a {
      height: 30px;
      line-height: 28px;
      padding: 0 1.5em;
    }
  }
`
export const UserMsg = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: white;
    margin-left: ${spacing.small};
    transform: rotate(90deg);
  }
  span {
    cursor: pointer;
    max-width: 80px;
    ${textEllipsis};
    :hover {
      color: white;
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 ${spacing.small};
    span {
      max-width: 60px;
      ${textEllipsis}
    }
  }
`
export const UserAvatar = styled.p`
  background-color: ${color.primary};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: white;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  margin-right: ${spacing.small};
`
export const GoCloudLogo = styled.div`
  width: 80px;
  svg {
    transform: translateY(-2px);
  }
`
export const QingjiaoLogo = styled.div`
  width: 60px;
  svg {
    transform: translateY(-2px);
  }
`

export const SubText = styled.span`
  font-size: 10px;
  color: #f18826;
  margin-left: 4px;
  position: absolute;
  top: -4px;
`
