import React from 'react'
import PropTypes from 'prop-types'
import { ContainedButton, OutlinedButton, PaginationButton } from './atoms'

class Button extends React.PureComponent {
  handleClick = e => {
    const { onClick } = this.props
    if (onClick) {
      onClick(e)
    }
  }
  render() {
    const {
      color = 'default',
      variant = 'contained',
      type,
      children,
      className,
      backgroundColor = 'transparent',
      disabled = false,
      ...rest
    } = this.props
    const Tag = {
      contained: ContainedButton,
      outlined: OutlinedButton,
      pagination: PaginationButton,
    }[variant]
    return (
      <Tag
        {...rest}
        type={type}
        color={color}
        backgroundcolor={backgroundColor}
        onClick={this.handleClick}
        className={`ripple${className ? ` ${className}` : ''}`}
        disabled={disabled}
      >
        {children}
      </Tag>
    )
  }
}

Button.propTypes = {
  color: PropTypes.oneOf(['default', 'primary']),
  backgroundColor: PropTypes.string,
  // size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  variant: PropTypes.oneOf(['outlined', 'contained', 'pagination']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Button
