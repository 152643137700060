import React from 'react'
import styled from 'styled-components'
import Modal from '../modal'
import Success from './images/success.png'
const ThanksModalContent = styled.div`
  height: 120px;
  font-size: 18px;
  color: #666666;
`
const ThanksModalFooter = styled.div`
  text-align: right;
  color: #999;
  font-size: 14px;
`
const SuccessIcon = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #13cb75;
  border-radius: 50%;
  text-align: center;
  margin-right: 12px;
`
const ThanksModal = ({ visible = false, onClose }) => {
  const modalProps = {
    baseStyle: true,
    titleStyle: { background: '#fff' },
    bodyStyle: { padding: '0 20px 30px' },
    visible,
    title: '',
    onClose,
  }
  return (
    <>
      <Modal {...modalProps}>
        <ThanksModalContent>
          <SuccessIcon>
            <img src={Success} alt='success' />
          </SuccessIcon>
          感谢您，反馈我们已经收到
        </ThanksModalContent>
        <ThanksModalFooter>3s后自动关闭</ThanksModalFooter>
      </Modal>
    </>
  )
}
export default ThanksModal
