import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { range } from '../../utils'
import { ArrowIcon, SwipeNavWrap, SwipeNav, Container } from './atoms'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

class Swiper extends React.PureComponent {
  state = { index: 0, auto: true }
  handleChangeIndex = index => {
    this.setState({ index })
  }
  handleNextClick = () => {
    const length = this.props.children.length
    this.setState(prev => ({
      index: prev.index + 1 === length ? 0 : prev.index + 1,
    }))
  }
  handlePrevClick = () => {
    const length = this.props.children.length
    this.setState(prev => ({
      index: prev.index === 0 ? length - 1 : prev.index - 1,
    }))
  }
  handleFocus = () => {
    this.setState({ auto: false })
  }
  handleBlur = () => {
    this.setState({ auto: true })
  }
  render() {
    const { index, auto } = this.state
    const { children, navBottom, interval = 7000, ...rest } = this.props
    return (
      <Container {...rest}>
        <AutoPlaySwipeableViews
          index={index}
          interval={interval}
          autoplay={auto}
          enableMouseEvents
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChangeIndex={this.handleChangeIndex}
        >
          {children}
        </AutoPlaySwipeableViews>
        <SwipeNavWrap bottom={navBottom}>
          {range(children.length).map(i => (
            <SwipeNav key={i} active={i === index} onClick={this.handleChangeIndex.bind(this, i)} />
          ))}
        </SwipeNavWrap>
        <ArrowIcon
          onClick={this.handlePrevClick}
          style={{ left: 150, transform: 'rotate(180deg)' }}
        />
        <ArrowIcon onClick={this.handleNextClick} style={{ right: 150 }} />
      </Container>
    )
  }
}

export default Swiper
