export const typography = Object.freeze({
  h1: '3rem', // NOTE: 48px on desktop
  title: '2.25rem', // NOTE: 36px on desktop
  h2: '1.875rem', // NOTE: 30px on desktop
  h3: '1.5rem', // NOTE: 24px on desktop
  h4: '1.125rem', // NOTE: 18px on desktop
  text: '1rem', // NOTE: 16px on desktop
  textSmall: '0.875rem', // NOTE: 14px on desktop
  textThin: '0.75rem', // NOTE: 12px on desktop
})
export const color = Object.freeze({
  amber: '#fb6b4a',
  white: '#fff',
  primary: '#13cb75',
  secondary: '#1cc300',
  text: '#dddddd',
  prompt: '#999999',
  divider: '#999999',
  panel: '#333333',
  background: '#222222',
  input: '#333333',
  highlight: '#3fae49',
  vermilion: '#ef6147',
  carrot: '#f38b29',
  caculate: '#282828',
  lightCaculate: '#242424',
  line: '#505050',
  subLine: '#89E5BA',
  blackPlus: '#181818',
  card: '#282828',
  subTitle: '#666666',
})
export const spacing = Object.freeze({
  small: '10px',
  base: '20px',
  large: '30px',
})
