import styled from 'styled-components'
import Media from '../theme/media'

const Wrapper = styled.div`
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : '1400px')};
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${Media.lessThan(Media.small)} {
    padding: 0 1rem;
    padding: ${(p) => (p.padding ? p.padding : '0 1rem')};
  }
`

export default Wrapper
