import React from 'react'

import { Anchor } from '../atoms'
import { LicenceText, NavigatorAnchor, PartnerWrap } from './atoms'

import PoliceIcon from './images/police.png'

class Partner extends React.PureComponent {
  render() {
    const year = new Date().getFullYear()

    return (
      <PartnerWrap halign='space-between'>
        <p>
          <img src={PoliceIcon} alt='' style={{ marginRight: 5, verticalAlign: 'top' }} />© {year}
          <Anchor href='https://www.rayvision.com' rel='nofollow'>
            深圳市瑞云科技股份有限公司{' '}
          </Anchor>
          <Anchor
            target='_blank'
            href='https://beian.miit.gov.cn/#/Integrated/index'
            rel='nofollow'
          >
            粤ICP备12028569号
          </Anchor>
          <LicenceText>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</LicenceText>
        </p>
        <p className='navigator-text'>
          Renderbus
          <NavigatorAnchor to='/'>渲染农场</NavigatorAnchor>
          ，海量机器
          <NavigatorAnchor to='/'>云渲染</NavigatorAnchor>
          平台
        </p>
      </PartnerWrap>
    )
  }
}

export default Partner
