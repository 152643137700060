import React from 'react'

const SvgCg02 = props => (
  <svg id='cg-02_svg___\u56FE\u5C42_1' data-name='\u56FE\u5C42 1' viewBox='0 0 25 25' {...props}>
    <defs>
      <style>{'.cg-02_svg__cls-1{fill:#12cc75;stroke-width:0}'}</style>
    </defs>
    <path
      className='cg-02_svg__cls-1'
      d='M9.1 23l.9-1.5h2.5v-5.9c-3.3 0-5.9-2.9-5.9-6.4V2h5.9V0H5.6c-.6 0-1 .4-1 1v3H1c-.6 0-1 .4-1 1 0 4.1 2.4 6.4 5 6.7.9 3.1 3.4 5.4 6.5 5.8v2H9.4c-.3 0-.7.2-.9.5l-2.1 3.5c-.2.3-.2.7 0 1 .2.3.5.5.9.5h5.2v-2H9.1zM4.6 9.2v.4C3.4 9.2 2.3 8 2.1 6h2.5v3.2z'
    />
    <path
      className='cg-02_svg__cls-1'
      d='M15.9 23l-.9-1.5h-2.5v-5.9c3.3 0 5.9-2.9 5.9-6.4V2h-5.9V0h6.9c.6 0 1 .4 1 1v3H24c.6 0 1 .4 1 1 0 4.1-2.4 6.4-5 6.7-.9 3.1-3.4 5.4-6.5 5.8v2h2.1c.3 0 .7.2.9.5l2.1 3.5c.2.3.2.7 0 1-.2.3-.5.5-.9.5h-5.2v-2h3.4zm4.5-13.8v.4c1.2-.4 2.3-1.6 2.5-3.6h-2.5v3.2z'
    />
  </svg>
)

export default SvgCg02
