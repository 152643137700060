import styled from 'styled-components'
import Flex from '../flex'
import { Anchor } from '../atoms'
import { Media, typography, spacing } from '../../theme'

export const BannerContainer = styled(Flex)`
  height: 360px;
  background: #191919;
  canvas {
    padding-top: 8px;
  }
  ${Media.lessThan(Media.large)} {
    align-items: flex-start;
  }
`

export const WordContainer = styled.div`
  margin: 70px ${spacing.base} 0;
  overflow-y: hidden;
  height: 18em;
  ${Media.lessThan(Media.large)} {
    text-align: center;
  }
`

export const Word = styled(Anchor)`
  display: inline-block;
  white-space: nowrap;
  margin: 0 5px;
  cursor: pointer;
  color: #349fd5;
  line-height: ${typography.text};
  font-size: ${typography.text};
`
