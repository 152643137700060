import React, { Component } from 'react'

import Item from './item'
import { BreadcrumbContainer } from './atoms'

class Breadcrumb extends Component {
  static Item = Item
  render() {
    const { separator, children, inPost } = this.props
    return (
      <BreadcrumbContainer separator={separator} inPost={inPost}>
        {children}
      </BreadcrumbContainer>
    )
  }
}
export default Breadcrumb
