import React, { useEffect, useState } from 'react'

import Flex from '../flex'
import { Row, Column } from '../grid'
import WeiboIcon from './images/weibo.png'
import MediaPlatformIcon from './images/media-platform.png'
import { useMedia } from 'use-media'

import {
  Title,
  DeusColumn,
  QRCodeWrap,
  BlockAnchor,
  VerticalDivider,
  PostCardsContainer,
  ContactPhone,
  NavigationAnchor,
  ContactUsColumn,
  IndustryColumn,
} from './atoms'

var moment = require('moment')
const PostCards = ({ info }) => {
  const [isShow, setIsShow] = useState(false)
  const isMobile = useMedia({ maxWidth: 600 })

  useEffect(() => {
    if (typeof window !== 'undefined')
      setIsShow(
        typeof window !== 'undefined' &&
          (window.location.pathname === '/' ||
            window.location.pathname.includes('share/post') ||
            window.location.pathname.includes('news/post')),
      )
  }, [])

  return (
    <PostCardsContainer>
      <Row>
        <DeusColumn lg='1'>
          <Title>瑞云科技</Title>
          {!isMobile && (
            <NavigationAnchor to='/about.html' target='_blank' rel='nofollow'>
              关于我们
            </NavigationAnchor>
          )}
          <BlockAnchor href='https://rayvision.zhiye.com/' target='_blank' rel='nofollow'>
            招纳贤士
          </BlockAnchor>
          <NavigationAnchor to='/share/' target='_blank'>
            专题分享
          </NavigationAnchor>
          <BlockAnchor href='https://ask.renderbus.com' target='_blank'>
            渲染问答
          </BlockAnchor>
          <NavigationAnchor to='/user-evaluation.html' target='_blank' rel='nofollow'>
            用户评价
          </NavigationAnchor>
          <NavigationAnchor to='/raytools.html' target='_blank'>
            瑞兔百宝箱
          </NavigationAnchor>
        </DeusColumn>
        <Column lg='1' xs='6'>
          <Title>瑞云活动</Title>
          <NavigationAnchor to='/gocloud.html#enterprise-section' target='_blank'>
            企业专享
          </NavigationAnchor>
          <NavigationAnchor to='/gocloud.html' target='_blank'>
            教育优惠
          </NavigationAnchor>
          <NavigationAnchor to='/go-cloud.html' target='_blank'>
            青云平台
          </NavigationAnchor>
          <NavigationAnchor to='/collaborative-cloud-production.html' target='_blank'>
            云渲染管理系统
          </NavigationAnchor>
          <NavigationAnchor to='/aspera.html' target='_blank'>
            Aspera
          </NavigationAnchor>
          <NavigationAnchor to='/promotion.html' target='_blank' rel='nofollow' className='share'>
            活动资讯
          </NavigationAnchor>
          {isMobile && (
            <NavigationAnchor
              to='/about.html'
              target='_blank'
              rel='nofollow'
              className='share-mobile'
            >
              关于我们
            </NavigationAnchor>
          )}
        </Column>
        <DeusColumn lg='1'>
          <VerticalDivider />
        </DeusColumn>
        <IndustryColumn lg='4' isShow={isShow}>
          <NavigationAnchor to='/share/' target='_blank'>
            <Title>行业资讯</Title>
          </NavigationAnchor>
          {info &&
            info.map(({ node }, index) => (
              <NavigationAnchor key={index} to={`/share/${node.slug}/`} target='_blank'>
                <span className='navigation-title'>{node.title}</span>
                <span>{moment(node.updatedAt).format('YYYY-MM-DD')}</span>
              </NavigationAnchor>
            ))}
        </IndustryColumn>
        <DeusColumn lg='1'>
          <VerticalDivider />
        </DeusColumn>
        <ContactUsColumn lg='2' xs='6'>
          <Title>联系我们</Title>
          <BlockAnchor
            target='_blank'
            rel='noopener noreferrer nofollow'
            href='https://wpa1.qq.com/ujoglPGq?_type=wpa&qidian=true'
          >
            效果图咨询
          </BlockAnchor>
          <BlockAnchor
            target='_blank'
            rel='noopener noreferrer nofollow'
            href='https://wpa1.qq.com/ujoglPGq?_type=wpa&qidian=true'
          >
            影视动画咨询
          </BlockAnchor>
          <BlockAnchor href='mailto:service@rayvision.com' rel='noopener noreferrer'>
            service@rayvision.com
          </BlockAnchor>
          <span>24小时服务热线：</span>
          <ContactPhone>
            400-003-4560
            <BlockAnchor href='tel:400-003-4560' rel='noopener noreferrer'>
              点击拨打
            </BlockAnchor>
          </ContactPhone>
          <NavigationAnchor
            target='_blank'
            rel='noopener noreferrer nofollow'
            to='/about.html#contact_us'
          >
            查看总部/分公司地址
          </NavigationAnchor>
        </ContactUsColumn>
        <Column lg='2' sm='12'>
          <Title style={{ padding: '0 20px' }} className='hide-at-mobile'>
            关注瑞云
          </Title>
          <QRCodeWrap halign='space-between' valign='flex-start'>
            <Flex direction='column'>
              <img src={MediaPlatformIcon} alt='' />
              <p>
                关注瑞云官方微信
                <br />
                再送10元动画渲染券
              </p>
            </Flex>
            <Flex direction='column'>
              <img src={WeiboIcon} alt='' />
              <BlockAnchor
                style={{ marginTop: '10px' }}
                target='_blank'
                rel='nofollow'
                href='https://weibo.com/renderbus?from=feed&loc=at&nick=Renderbus%E7%91%9E%E4%BA%91%E6%B8%B2%E6%9F%93&is_all=1'
              >
                瑞云微博
              </BlockAnchor>
            </Flex>
          </QRCodeWrap>
        </Column>
      </Row>
    </PostCardsContainer>
  )
}

export default PostCards
