import { darken } from 'polished'
import styled from 'styled-components'
import { color, spacing, transition, Media } from '../../theme'
export const FadeNavWrap = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: ${({ bottom = 20 }) => `${bottom}px`};
`
export const FadeNav = styled.span`
  cursor: pointer;
  position: relative;
  display: block;
  width: 40px;
  height: 4px;
  background-color: ${darken(0.1, color.prompt)};
  transition: ${transition('background-color')};
  overflow: hidden;
  & + & {
    margin-left: ${spacing.base};
  }
  ${Media.lessThan(Media.small)} {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  ::after {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${color.primary};
    animation: ${({ active, interval }) =>
      active && `${interval / 1000}s changeWidth linear normal`};
  }
  @keyframes changeWidth {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }
  @-webkit-keyframes changeWidth {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }
`
export const Container = styled.div`
  height: ${({ height }) => height};
  position: relative;
  overflow: hidden;
`
