import React from 'react'

const SvgProduct03 = props => (
  <svg viewBox='0 0 24 24' {...props}>
    <defs>
      <style>{'.product-03_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='product-03_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='product-03_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <g id='product-03_svg__Group'>
          <path
            id='product-03_svg__Rectangle-54'
            className='product-03_svg__cls-1'
            d='M12 12l12 6-12 6-12-6zm0 2.24L4.47 18 12 21.76 19.53 18z'
          />
          <path
            id='product-03_svg__Combined-Shape'
            className='product-03_svg__cls-1'
            d='M11 21.26V5.11L2.92 17.22zm2-16.15v16.15l8.08-4zM0 18L12 0l12 18-12 6z'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgProduct03
