import React from 'react'

const SvgDownload02 = props => (
  <svg id='download_02_svg__OBJECTS' x={0} y={0} viewBox='0 0 46 46' xmlSpace='preserve' {...props}>
    <style>{'.download_02_svg__st1{fill:#fff}'}</style>
    <linearGradient
      id='download_02_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={2.35}
      y1={2203.65}
      x2={43.65}
      y2={2162.35}
      gradientTransform='translate(0 -2160)'
    >
      <stop offset={0.5} stopColor='#2789ff' />
      <stop offset={1} stopColor='#45bee0' />
    </linearGradient>
    <path
      d='M38 46H8c-4.4 0-8-3.6-8-8V8c0-4.4 3.6-8 8-8h30c4.4 0 8 3.6 8 8v30c0 4.4-3.6 8-8 8z'
      fill='url(#download_02_svg__SVGID_1_)'
    />
    <path
      className='download_02_svg__st1'
      d='M37.9 31.3v4.2c0 .8-.6 1.4-1.4 1.4h-27c-.9.1-1.5-.5-1.5-1.3v-3.7c0-.4.1-.8.2-1.2.2-.4.4-.7.7-1l8.3-8.7c.3-.3.7-.5 1.1-.5s.8.1 1.2.4l2.3 2.2 3.7-4.8c.3-.4.8-.6 1.3-.6s1 .2 1.3.6l9 11.1c.5.5.8 1.2.8 1.9zM13.9 9c2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2c0-2.3 1.9-4.2 4.2-4.2z'
    />
  </svg>
)

export default SvgDownload02
