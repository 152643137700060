import * as React from 'react'
const SVGComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' {...props}>
    <path
      fillRule='evenodd'
      fill='rgb(255, 255, 255)'
      d='M20.999,24.0 L16.999,24.0 C16.447,24.0 15.999,23.552 15.999,23.0 C15.999,22.447 16.447,22.0 16.999,22.0 L20.999,22.0 C21.551,22.0 21.999,21.551 21.999,21.0 L21.999,16.999 C21.999,16.447 22.447,15.999 22.999,15.999 C23.552,15.999 23.999,16.447 23.999,16.999 L23.999,21.0 C23.999,22.654 22.654,24.0 20.999,24.0 ZM22.999,8.0 C22.447,8.0 21.999,7.552 21.999,7.0 L21.999,2.999 C21.999,2.448 21.551,1.999 20.999,1.999 L16.999,1.999 C16.447,1.999 15.999,1.552 15.999,0.999 C15.999,0.447 16.447,0.0 16.999,0.0 L20.999,0.0 C22.654,0.0 23.999,1.345 23.999,2.999 L23.999,7.0 C23.999,7.552 23.552,8.0 22.999,8.0 ZM17.781,5.221 C18.125,5.652 18.55,6.281 17.624,6.626 L12.999,10.326 L12.999,14.760 L16.999,14.760 C17.552,14.760 17.999,15.208 17.999,15.760 C17.999,16.312 17.552,16.760 16.999,16.760 L12.999,16.760 L12.999,18.406 C12.999,18.959 12.552,19.406 11.999,19.406 C11.446,19.406 10.999,18.959 10.999,18.406 L10.999,16.760 L7.0,16.760 C6.447,16.760 6.0,16.312 6.0,15.760 C6.0,15.208 6.447,14.760 7.0,14.760 L10.999,14.760 L10.999,10.326 L6.375,6.626 C5.943,6.281 5.874,5.652 6.219,5.221 C6.564,4.789 7.194,4.720 7.624,5.64 L11.999,8.564 L16.374,5.64 C16.807,4.718 17.434,4.790 17.781,5.221 ZM7.0,1.999 L3.0,1.999 C2.448,1.999 2.0,2.448 2.0,2.999 L2.0,7.0 C2.0,7.552 1.552,8.0 1.0,8.0 C0.447,8.0 0.0,7.552 0.0,7.0 L0.0,2.999 C0.0,1.345 1.345,0.0 3.0,0.0 L7.0,0.0 C7.552,0.0 8.0,0.447 8.0,0.999 C8.0,1.552 7.552,1.999 7.0,1.999 ZM1.0,15.999 C1.552,15.999 2.0,16.447 2.0,16.999 L2.0,21.0 C2.0,21.551 2.448,22.0 3.0,22.0 L7.0,22.0 C7.552,22.0 8.0,22.447 8.0,23.0 C8.0,23.552 7.552,24.0 7.0,24.0 L3.0,24.0 C1.345,24.0 0.0,22.654 0.0,21.0 L0.0,16.999 C0.0,16.447 0.447,15.999 1.0,15.999 Z'
    />
    <path
      fillRule='evenodd'
      fill='rgb(19, 203, 117)'
      d='M16.999,13.0 L7.0,13.0 C6.447,13.0 6.0,12.552 6.0,12.0 C6.0,11.447 6.447,10.999 7.0,10.999 L16.999,10.999 C17.552,10.999 17.999,11.447 17.999,12.0 C17.999,12.552 17.552,13.0 16.999,13.0 Z'
    />
  </svg>
)
export default SVGComponent
