import styled from 'styled-components'
import { color, rotate } from '../../theme'

export const LoadingIcon = styled.div`
  margin: auto;
  width: ${p => p.size || '50px'};
  height: ${p => p.size || '50px'};
  border: ${color.divider} 2px solid;
  border-top: none;
  border-left: none;
  animation: ${rotate} 1000ms infinite linear;
  border-radius: 50%;
`
