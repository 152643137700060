const SearchIcon =
  'M849.5 822.5L738.9 712c60-60.7 97.1-144.1 97.1-236 0-185.3-150.7-336-336-336S164 290.7 164 476s150.7 336 336 336c67.4 0 130-20.1 182.7-54.4l115.9 115.9c7 7 16.2 10.5 25.5 10.5s18.4-3.5 25.5-10.5c13.9-14.1 13.9-36.9-0.1-51zM236 476c0-145.6 118.4-264 264-264s264 118.4 264 264-118.4 264-264 264-264-118.4-264-264z'
const GoIcon =
  'M885.29 162.73A519.68 519.68 0 0 0 508.033 0C288.982 0 93.227 137.77 20.907 342.784a27.179 27.179 0 0 0 51.242 17.792C136.875 177.152 312.064 53.888 508.032 53.888a465.067 465.067 0 0 1 337.536 145.621 452.523 452.523 0 0 1 122.965 345.046C952.832 770.9 768.94 953.387 540.843 969.003c-231.168 16-440.192-141.782-485.547-366.635-3.072-15.36 0.981-31.232 11.093-43.52 10.496-12.63 25.942-19.883 42.411-19.883h524.032l-122.368 93.44a26.795 26.795 0 0 0-4.95 37.846 27.264 27.264 0 0 0 38.102 4.906l185.6-141.781a26.41 26.41 0 0 0 4.693-4.821l0.939-1.28a27.861 27.861 0 0 0 3.456-6.528l0.085-0.128a26.24 26.24 0 0 0 1.28-7.467v-1.536a26.368 26.368 0 0 0-1.024-7.168l-0.384-1.067a26.283 26.283 0 0 0-2.858-5.802l-0.768-1.28-0.47-0.726a26.795 26.795 0 0 0-4.693-4.608l-0.384-0.426L540.203 347.86a27.307 27.307 0 0 0-38.102 5.12 26.837 26.837 0 0 0 5.163 37.76l124.885 94.294H108.8c-32.81 0-63.573 14.506-84.352 39.68a105.387 105.387 0 0 0-22.357 88.234c48.298 239.702 262.656 411.008 506.752 411.008 11.818 0 23.765-0.426 35.712-1.194 255.018-17.494 460.586-221.44 478.165-474.496A505.6 505.6 0 0 0 885.29 162.73'
const ArrowRightIcon =
  'M676.8 512.128a32 32 0 0 1-9.984 23.232l-265.6 251.264a32 32 0 0 1-43.968-46.528l241.088-228.032L357.248 283.84a32.064 32.064 0 0 1-1.28-45.248c12.16-12.8 32.448-13.376 45.248-1.28l265.6 251.52a32 32 0 0 1 9.984 23.296z'
const ArrowLeftIcon =
  'M347.2 511.872v-0.008c0-9.137 3.829-17.379 9.97-23.21l265.614-251.277a31.888 31.888 0 0 1 21.984-8.744c17.678 0 32.008 14.33 32.008 32.008 0 9.157-3.845 17.416-10.009 23.25L425.664 511.936 666.752 740.16c6.165 5.855 10.001 14.113 10.001 23.266 0 8.516-3.32 16.256-8.736 21.998-12.145 12.784-32.434 13.36-45.233 1.264l-265.6-251.52c-6.155-5.844-9.984-14.086-9.984-23.223v-0.076z'
const ClockIcon =
  'M737.28 516.48a32 32 0 1 1 0 64H497.536a32 32 0 0 1-32-32V233.344a32 32 0 0 1 64 0V516.48h207.616zM512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768z m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z'
const QQIcon =
  'M197.973 566.613c-27.306 68.267-34.133 129.707-6.826 143.36 13.653 6.827 40.96-13.653 61.44-40.96 6.826 34.134 27.306 68.267 61.44 95.574-40.96 13.653-61.44 34.133-61.44 54.613 0 40.96 54.613 68.267 122.88 68.267 61.44 0 116.053-27.307 122.88-54.614H512c13.653 27.307 68.267 54.614 129.707 54.614 68.266 0 122.88-27.307 122.88-68.267 0-20.48-20.48-40.96-54.614-54.613 27.307-27.307 54.614-61.44 61.44-95.574 20.48 34.134 47.787 54.614 61.44 40.96 20.48-13.653 20.48-75.093-6.826-143.36-20.48-54.613-47.787-88.746-68.267-95.573v-6.827c0-20.48-6.827-40.96-13.653-54.613v-6.827c0-6.826 0-20.48-6.827-27.306C730.453 245.76 648.533 136.533 512 136.533S293.547 245.76 286.72 382.293c0 6.827-6.827 13.654-6.827 20.48v6.827c-6.826 13.653-13.653 34.133-13.653 54.613v6.827c-20.48 6.827-47.787 47.787-68.267 95.573'
const QaIcon =
  'M512 0c-283.031 0-512 228.969-512 512s228.969 512 512 512c283.031 0 512-228.969 512-512-1.59-283.031-230.559-512-512-512zM542.211 812.521h-90.634v-90.634h90.634v90.634zM648.745 454.757c-9.54 17.491-28.621 38.161-55.652 65.193-28.621 27.031-46.112 47.702-50.882 58.832-4.77 11.13-7.95 30.211-7.95 60.422l7.95 23.851h-90.634l-1.59-25.441c1.59-30.211 6.36-57.242 14.311-77.913 9.54-22.261 28.621-47.702 57.242-77.913 27.031-27.031 42.932-47.702 49.292-57.242 4.77-9.54 7.95-20.671 7.95-36.571 0-22.261-6.36-39.752-20.671-52.472-14.311-12.72-33.391-20.671-58.832-20.671-54.062 0-73.143 38.161-77.913 106.534h-90.634v-9.54c1.59-31.801 4.77-55.652 12.72-74.733s19.081-36.571 36.571-52.472c17.491-15.901 36.571-27.031 57.242-33.391 20.671-6.36 41.342-11.13 63.602-11.13 46.112 0 85.863 14.311 116.075 41.342 30.211 27.031 46.112 65.193 46.112 111.304 0 23.851-4.77 44.522-14.311 62.012z'

const IconType = Object.freeze({
  Search: 'search',
  Go: 'go',
  ArrowLeft: 'arrow-left',
  ArrowRight: 'arrow-right',
  Clock: 'clock',
  QQ: 'qq',
  Question: 'question',
})

export const IconMap = new Map([
  [IconType.Search, SearchIcon],
  [IconType.Go, GoIcon],
  [IconType.ArrowLeft, ArrowLeftIcon],
  [IconType.ArrowRight, ArrowRightIcon],
  [IconType.Clock, ClockIcon],
  [IconType.QQ, QQIcon],
  [IconType.Question, QaIcon],
])

export default IconType
