import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { IconType, Icon } from '../icon'
import Highlight from '../highlight'
import { CmsContentService } from '../../service'

import {
  PostCardContainer,
  PostCardImg,
  PostCardContent,
  PostCardTitle,
  PostCardIntro,
  PostCardDate,
  PostCardMsg,
  PostCardCategory,
  PostTagsWrapper,
} from './atoms'

var moment = require('moment')

class CmsPostCard extends React.PureComponent {
  removeRuiyun(category) {
    if (category) {
      const categoryReg = /^瑞云/
      return category.replace(categoryReg, '')
    }
    return ''
  }

  render() {
    const { title, slug, content, tinyMceContent, upDate, category, tags, highlight } = this.props

    const utc8Data = moment(upDate)
      .utcOffset(8 * 60)
      .format('YYYY-MM-DD')
    const coverImgSrc = CmsContentService.getCoverImg(content, tinyMceContent)
    const intro = CmsContentService.getAbstract(content, tinyMceContent)
    return (
      <PostCardContainer>
        <PostCardImg>
          <Link to={`/${slug}/`} target='_blank'>
            <img src={coverImgSrc} alt={title} />
          </Link>
        </PostCardImg>
        <PostCardContent>
          <PostCardTitle className='post-card-title'>
            <Link to={`/${slug}/`} target='_blank'>
              {highlight ? <Highlight htmlFragment={title} keywords={highlight} /> : title}
            </Link>
          </PostCardTitle>
          <PostCardIntro>
            {highlight ? <Highlight htmlFragment={intro} keywords={highlight} /> : intro}
          </PostCardIntro>
          <PostCardMsg>
            <PostCardDate>
              <Icon type={IconType.Clock} />
              {utc8Data}
            </PostCardDate>
            <PostTagsWrapper>
              {tags &&
                tags.map(({ name, slug }, index) => (
                  <Link key={index} to={`/${slug}/`}>
                    {name}
                  </Link>
                ))}
            </PostTagsWrapper>
          </PostCardMsg>
        </PostCardContent>
        {category ? (
          <PostCardCategory category={category}>{this.removeRuiyun(category)}</PostCardCategory>
        ) : null}
      </PostCardContainer>
    )
  }
}

CmsPostCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  tinyMceContent: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.string,
}

export default CmsPostCard
