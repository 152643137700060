import React from 'react'
import ReactDOM from 'react-dom'
import Manager from './manager'

class Producer {
  // TODO: implement other message type API later
  static info(content, duration) {
    this.getInstance().then(ins => {
      ins.add(content, duration)
    })
  }
  static instance
  static portalID = 'ELPSYCONGROO'
  static createPortal() {
    const existingPortal = document.getElementById(Producer.portalID)
    if (existingPortal) {
      return existingPortal
    }
    const portal = document.createElement('div')
    portal.id = this.portalID
    document.body.appendChild(portal)
    return portal
  }
  static init() {
    if (typeof window === 'undefined') {
      throw new Error('Toast only can instantiation on browser')
    }
    return new Promise(resolve => {
      const saveRef = ref => resolve(ref)
      ReactDOM.render(React.createElement(Manager, { ref: saveRef }), this.createPortal())
    })
  }
  static async getInstance() {
    if (!this.instance) {
      this.instance = await this.init()
    }
    return this.instance
  }
}

export default Producer
