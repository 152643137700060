import * as React from 'react'
const SVGComponent = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    // xmlns:xlink='http://www.w3.org/1999/xlink'
    width='19px'
    height='22px'
    {...props}
  >
    <path
      fillRule='evenodd'
      fill='rgb(19, 203, 117)'
      d='M18.506,14.845 C18.313,15.42 18.60,15.140 17.806,15.140 C17.553,15.140 17.299,15.42 17.106,14.845 L10.874,8.520 L10.874,20.974 C10.874,21.529 10.431,21.979 9.884,21.979 C9.336,21.979 8.893,21.529 8.893,20.974 L8.893,8.520 L2.661,14.845 C2.274,15.238 1.648,15.238 1.261,14.845 C0.874,14.453 0.874,13.817 1.261,13.424 L9.183,5.383 C9.274,5.290 9.384,5.217 9.505,5.166 C9.747,5.64 10.20,5.64 10.262,5.166 C10.383,5.217 10.493,5.290 10.584,5.383 L18.506,13.424 C18.893,13.817 18.893,14.453 18.506,14.845 Z'
    />
    <path
      fillRule='evenodd'
      fill='rgb(19, 203, 117)'
      d='M17.0,2.0 L2.999,2.0 C2.447,2.0 1.999,1.552 1.999,1.0 C1.999,0.447 2.447,0.0 2.999,0.0 L17.0,0.0 C17.552,0.0 18.0,0.447 18.0,1.0 C18.0,1.552 17.552,2.0 17.0,2.0 Z'
    />
  </svg>
)
export default SVGComponent
