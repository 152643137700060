/* eslint-disable */
import { checkBrowser } from '@renderbus/common/service'
const accountPreDomain = 'account-pre'
const accountDomain = 'account'
const TaskPreDomain = 'task-pre'
const TaskDomain = 'task'

export const getDomain = () => {
  if (!checkBrowser()) return 0
  if (location.hostname.includes('foxrenderfarm') || location.hostname.includes('localhost')) {
    return 1
  }
  return 0
}

export const getEnvironmentUrl = () => {
  if (!checkBrowser()) return ''
  const lastDomain = document.domain.split('.')[0]
  return lastDomain === TaskDomain ? TaskDomain : TaskPreDomain
}
export const getDomainName = (level = 1) => {
  if (!checkBrowser()) return
  return document.domain.split('.').reverse()[level - 1]
}

export const getUserCenterRedirectDomain = () => {
  if (!checkBrowser()) return
  const DomainMap = new Map([
    [accountPreDomain, TaskPreDomain],
    [accountDomain, TaskDomain],
    [TaskPreDomain, accountPreDomain],
    [TaskDomain, accountDomain],
  ])
  return `${DomainMap.get(getDomainName(3))}.${getDomainName(2)}.${getDomainName(1)}`
}

export const isTaskDomain = () => {
  if (!checkBrowser()) return
  return [TaskDomain, TaskPreDomain].includes(getDomainName(3))
}

export const isUserCenterDomain = () => {
  if (!checkBrowser()) return
  return [accountDomain, accountPreDomain].includes(getDomainName(3))
}
