import React, { Component, Fragment } from 'react'

import { navigate } from 'gatsby'
import { Divider, Highlight } from '../../../components'
import { IconType, Icon } from '../../icon'

import noResult from './images/support_nofound.png'
import { CmsContentService } from '../../../service'
import {
  SupportSearchResultContainer,
  SupportSearchResultItem,
  SupportResultTitle,
  SupportResultAbstract,
  QaNoResultContainer,
  PostCardDate,
  EmptyImg,
  EmptyText,
} from './atoms'

class SupportSearchResult extends Component {
  getAnswer(answer) {
    return answer.replace(/(\r\n|__|#|<.*?>|!?\[.*\]\(.*?\)!?)/g, '')
  }
  render() {
    let { result, searchContent, currentPage } = this.props
    let resultData = []
    if (result.length > 0) {
      for (let i = 0; i < result.length; i += 10) {
        resultData.push(result.slice(i, i + 10))
      }
    }
    return resultData[currentPage - 1] && resultData[currentPage - 1].length > 0 ? (
      <SupportSearchResultContainer>
        {resultData[currentPage - 1].map((page, index) => (
          <Fragment key={index}>
            <SupportSearchResultItem onClick={() => navigate(`/${page.slug}`)}>
              <SupportResultTitle>
                <Highlight htmlFragment={page.question} keywords={searchContent} />
              </SupportResultTitle>
              <SupportResultAbstract style={{ height: '50px', lineHeight: '25px' }}>
                {CmsContentService.getAbstract(page.answer.answer) ? (
                  <Highlight
                    htmlFragment={CmsContentService.getAbstract(page.answer.answer)}
                    keywords={searchContent}
                  />
                ) : (
                  this.getAnswer(page.answer.answer)
                )}
              </SupportResultAbstract>
              <PostCardDate>
                <Icon type={IconType.Clock} />
                {page.updatedAt.slice(0, 10)} {page.updatedAt.slice(11, 19)}
              </PostCardDate>
            </SupportSearchResultItem>
            <Divider />
          </Fragment>
        ))}
      </SupportSearchResultContainer>
    ) : (
      <QaNoResultContainer>
        <EmptyImg src={noResult} />
        <EmptyText>抱歉，暂无相关问题，请尝试更改关键字或者联系在线客服！</EmptyText>
      </QaNoResultContainer>
    )
  }
}

export default SupportSearchResult
