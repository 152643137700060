import styled, { css } from 'styled-components'
import { color, transition, typography, shadows, spacing } from '../../theme'
import { lighten } from 'polished'

const { primary, secondary, panel } = color

const ButtonBase = css`
  cursor: pointer;
  text-decoration: none;
  font-size: ${typography.textSmall};
  height: 40px;
  line-height: 40px;
  padding: 0 2.5em;
  position: relative;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
  white-space: nowrap;
  user-select: none;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: ${shadows[1]};
  background: ${p => p.disabled && `${color.prompt} !important`};
  pointer-events: ${p => p.disabled && 'none !important'};
  :active,
  :focus {
    outline: 0;
  }
  :not([disabled]):active {
    box-shadow: none;
  }
  :disabled {
    pointer-events: none;
    filter: invert(0.8) grayscale(100%);
  }
`
export const ContainedButton = styled.button`
  ${ButtonBase};
  color: ${p => (p.color === 'default' ? 'white' : primary)};
  background-size: 300% 100%;
  border: 0 solid transparent;
  background-color: ${p => p.backgroundcolor};
  transition: ${transition(['background-position', 'box-shadow'])};
  background-image: ${p =>
    p.backgroundcolor === 'transparent'
      ? `linear-gradient(to right, ${primary}, ${secondary}, ${secondary}, ${primary})`
      : 'none'};
  :hover {
    background-position: 100% 0;
    box-shadow: ${shadows[3]};
  }
`
export const OutlinedButton = styled.button`
  ${ButtonBase};
  background-image: none;
  color: ${p => (p.color === 'default' ? 'white' : primary)};
  background-color: transparent;
  line-height: 36px;
  border: 2px solid ${p => (p.color === 'default' ? 'white' : primary)};
  transition: ${transition(['background-color', 'color', 'box-shadow'])};
  :hover {
    box-shadow: ${shadows[3]};
    color: ${p => (p.color === 'default' ? primary : 'white')};
    background-color: ${p => (p.color === 'default' ? 'white' : primary)};
  }
`
const PaginationStatus = ({ isBan, active }) => css`
  cursor: ${isBan ? 'not-allowed' : 'pointer'};
  color: ${active ? 'white' : panel};
  border: 1px solid ${active ? primary : lighten(0.6, panel)};
  background-color: ${active ? primary : 'transparent'};
  :hover {
    color: ${isBan ? panel : 'white'};
    border: 1px solid ${isBan ? lighten(0.6, panel) : primary};
    background-color: ${isBan ? 'transparent' : primary};
    svg {
      color: ${isBan ? panel : 'white'};
    }
  }
`

export const PaginationButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: ${spacing.small};
  text-align: center;
  ${PaginationStatus}
  margin: 5px;
  transition: ${transition(['color', 'border', 'background-color'])};
  svg {
    color: ${panel};
  }
  :active,
  :focus {
    outline: 0;
  }
  user-select: none;
`
