import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { IconType, Icon } from '../icon'
import Highlight from '../highlight'
import { CmsContentService } from '../../service'

import {
  PostCardContainer,
  PostCardContent,
  PostCardTitle,
  PostCardIntro,
  PostCardDate,
  PostCardMsg,
  PostCardImg,
} from './atoms'

class QaCard extends React.PureComponent {
  render() {
    const { question, slug, answer, tinyMceContent, date, tags, highlight } = this.props
    const intro =
      CmsContentService.getAbstract(answer, tinyMceContent) ||
      (answer || tinyMceContent).replace(/(\r\n|__|#|<.*?>|!?\[.*\]\(.*?\)!?)/g, '')
    return (
      <PostCardContainer>
        <PostCardContent>
          <PostCardTitle>
            <PostCardImg>
              <Icon type={IconType.Question} style={{ width: '1.5rem', height: '1.5rem' }} />
            </PostCardImg>
            <Link to={`/${slug}/`} target='_blank'>
              {highlight ? <Highlight htmlFragment={question} keywords={highlight} /> : question}
            </Link>
          </PostCardTitle>
          <PostCardIntro>
            {highlight ? <Highlight htmlFragment={intro} keywords={highlight} /> : intro}
          </PostCardIntro>
          <PostCardMsg>
            <PostCardDate>
              <Icon type={IconType.Clock} />
              {date}
            </PostCardDate>
            {tags &&
              tags.map(({ name, slug }, index) => (
                <Link key={index} to={`/${slug}/`} style={{ color: '#999', marginRight: '5px' }}>
                  {name}
                </Link>
              ))}
          </PostCardMsg>
        </PostCardContent>
      </PostCardContainer>
    )
  }
}

QaCard.propTypes = {
  question: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  tinyMceContent: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.string,
}

export default QaCard
