import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

const Li = styled.li`
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  display: flex;
  a {
    font-size: 14px;
    font-weight: 500;
    :hover {
      color: #000;
    }
  }
`

class Item extends Component {
  render() {
    const { href, children } = this.props
    return <Li>{href ? <a href={href}>{children}</a> : <Fragment>{children}</Fragment>}</Li>
  }
}

export default Item
