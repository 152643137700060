import React, { Component } from 'react'
import { navigate } from 'gatsby'

import Highlight from '../../highlight'
import {
  SupportInstantSearchResultContainer,
  SupportInstantSearchResultItem,
  SupportInstantClassification,
  SupportInstantResultContent,
  SupportInstantResultDetail,
} from './atoms'

class SupportInstantSearchResult extends Component {
  render() {
    const { result, searchContent } = this.props
    return searchContent ? (
      <SupportInstantSearchResultContainer>
        {result.length > 0 &&
          result.map((page, index) => (
            <SupportInstantSearchResultItem
              key={index}
              onClick={() =>
                navigate(`/${page.supportSlug}`, {
                  state: {
                    newPage: 1,
                  },
                })
              }
            >
              <SupportInstantClassification>
                <p>{page.supportBreadcrumb[0]}</p>
              </SupportInstantClassification>
              <SupportInstantResultContent>
                <Highlight htmlFragment={page.supportTitle} keywords={searchContent} />
                <SupportInstantResultDetail>
                  <Highlight
                    htmlFragment={page.supportAbstract}
                    keywords={searchContent}
                    type='partial'
                  />
                </SupportInstantResultDetail>
              </SupportInstantResultContent>
            </SupportInstantSearchResultItem>
          ))}
        {result.length === 0 && <div>{`没有 "${searchContent}" 相关文章`}</div>}
      </SupportInstantSearchResultContainer>
    ) : null
  }
}
export default SupportInstantSearchResult
