import React from 'react'

import { IsInMainContext } from '../layout'
import { NavAnchor, NavLink } from './atoms'
import { isTest } from '@renderbus/common/service'

class NavigationLink extends React.PureComponent {
  renderLink(isInMain) {
    const { children, target, isActive, to, isOtherWeb, ...rest } = this.props
    const reg = /^\/(?!\/)/
    const baseLink = isTest() ? 'http://pre.renderbus.com:8081' : 'https://www.renderbus.com'
    if (!target && reg.test(to) && isInMain) {
      return (
        <NavLink to={to} isActive={isActive} {...rest}>
          {children}
        </NavLink>
      )
    }
    return (
      <NavAnchor
        href={isOtherWeb ? to : `${baseLink}${to}`}
        isActive={isActive}
        target={target}
        {...rest}
      >
        {children}
      </NavAnchor>
    )
  }
  render() {
    return (
      <IsInMainContext.Consumer>{isInMain => this.renderLink(isInMain)}</IsInMainContext.Consumer>
    )
  }
}

export default NavigationLink
