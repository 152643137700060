import { darken } from 'polished'
import styled from 'styled-components'
import { hideSMDown, color, spacing, transition, Media } from '../../theme'

export const ArrowIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: 60px;
  height: 90px;
  ::after {
    content: '';
    position: absolute;
    display: block;
    top: 25px;
    left: -3px;
    width: 26px;
    height: 26px;
    transform: rotate(-45deg);
    border-radius: 4px;
    border-right: solid 4px ${darken(0.2, color.prompt)};
    border-bottom: solid 4px ${darken(0.2, color.prompt)};
    transition: ${transition('border-color')};
  }
  :hover {
    ::after {
      border-color: ${color.primary};
    }
  }
  ${hideSMDown};
`
export const SwipeNavWrap = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: ${({ bottom = 20 }) => `${bottom}px`};
`
export const SwipeNav = styled.span`
  cursor: pointer;
  display: block;
  width: 40px;
  height: 4px;
  background-color: ${darken(0.1, color.prompt)};
  transition: ${transition('background-color')};
  & + & {
    margin-left: ${spacing.base};
  }
  background-color: ${({ active }) => active && color.primary};
  ${Media.lessThan(Media.small)} {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`
export const Container = styled.div`
  position: relative;
`
