import React from 'react'
import NavigationLink from './navigation-link'
import { MobileMenuContainer, MobileMenuBlock, ArrowIcon } from './atoms'
import { isTest } from '@renderbus/common/service'

class MobileMenu extends React.PureComponent {
  state = {
    isProductExpand: false,
    isPriceExpand: false,
    isDownloadExpand: false,
    isCgExpand: false,
  }
  setIsProductExpand(isProductExpand) {
    this.setState(pre => ({ isProductExpand: !pre.isProductExpand }))
  }
  setIsPriceExpand() {
    this.setState(pre => ({ isPriceExpand: !pre.isPriceExpand }))
  }
  setIsDownloadExpand() {
    this.setState(pre => ({ isDownloadExpand: !pre.isDownloadExpand }))
  }
  setIsCgExpand() {
    this.setState(pre => ({ isCgExpand: !pre.isCgExpand }))
  }
  handleExpandClick(name) {
    switch (name) {
      case '产品服务':
        this.setIsProductExpand(true)
        break
      case '价格':
        this.setIsPriceExpand(true)
        break
      case '下载':
        this.setIsDownloadExpand(true)
        break
      case 'CG社区':
        this.setIsCgExpand(true)
        break
      default:
        break
    }
  }
  static MobileLinkConfig = [
    {
      name: '首页',
      link: '/',
    },
    {
      name: '产品服务',
      isExpandable: true,
      isExpand: this.isProductExpand,
      children: [
        {
          name: '影视动画渲染',
          link: '/film-tv-rendering.html',
        },
        {
          name: '效果图渲染',
          link: '/architectural-rendering.html',
        },
        {
          name: '云渲染管理系统',
          link: '/collaborative-cloud-production.html',
        },
        {
          name: '在线版权登记',
          link: isTest() ? 'https://ip-pre.renderbus.com/' : 'https://ip.renderbus.com/',
          alt: '版权登记',
          isOtherWeb: true,
        },
        {
          name: '瑞兔百宝箱',
          link: '/raytools.html',
        },
      ],
    },
    {
      name: '价格',
      isExpandable: true,
      isExpand: this.isPriceExpand,
      children: [
        {
          name: '影视动画价格',
          link: '/pricing.html',
        },
        {
          name: '效果图价格',
          link: '/effect_price.html',
        },
      ],
    },
    {
      name: '案例',
      link: '/client-works.html',
    },
    {
      name: '新闻',
      link: '/news/',
      target: '_blank',
    },
    {
      name: 'CG社区',
      isExpandable: true,
      isExpand: this.isCgExpand,
      children: [
        {
          name: '泛CG交流会',
          link: 'https://cgtalks.renderbus.com/',
          isOtherWeb: true,
          id: 'nav-cgtalks',
        },
        {
          name: '渲染大赛',
          link: '/rc2024.html',
          id: 'nav-cgchallenge',
        },
      ],
    },
    {
      name: '青云平台',
      link: '/go-cloud.html',
      id: 'nav-qcloud',
    },
    {
      name: '下载',
      isExpandable: true,
      isExpand: this.isDownloadExpand,
      children: [
        {
          name: '动画客户端',
          link: '/download.html',
          id: 'dhdesk',
        },
        {
          name: '效果图客户端',
          link: '/xgt-download.html',
          id: 'xgtdesk',
        },
      ],
    },
    {
      name: '帮助/教程',
      link: '/support-new/',
      target: '_blank',
    },
    {
      name: '登录',
      link: isTest() ? 'https://task-pre.renderbus.com/sso' : 'https://task.renderbus.com/sso',
      alt: '登录',
      isOtherWeb: true,
    },
  ]
  render() {
    const { location, isExpand } = this.props
    const { isPriceExpand, isProductExpand, isDownloadExpand, isCgExpand } = this.state
    return (
      <MobileMenuContainer isExpand={isExpand}>
        {MobileMenu.MobileLinkConfig.map((l, i) =>
          l.isExpandable ? (
            <div key={i}>
              <div
                onClick={() => this.handleExpandClick(l.name)}
                style={{ height: '40px', verticalAlign: 'top', lineHeight: '40px' }}
              >
                <span>{l.name}</span>
                <ArrowIcon
                  active={isPriceExpand}
                  style={{ display: l.name === '价格' ? 'block' : 'none' }}
                />
                <ArrowIcon
                  style={{ display: l.name === '产品服务' ? 'block' : 'none' }}
                  active={isProductExpand}
                />
                <ArrowIcon
                  active={isCgExpand}
                  style={{ display: l.name === 'CG社区' ? 'block' : 'none' }}
                />
                <ArrowIcon
                  style={{ display: l.name === '下载' ? 'block' : 'none' }}
                  active={isDownloadExpand}
                />
              </div>
              <MobileMenuBlock isExpand={l.name === '价格' && isPriceExpand}>
                {l.children.map((v, i) => (
                  <NavigationLink
                    key={i}
                    to={v.link}
                    title={v.alt || v.name}
                    isActive={location.pathname === v.link}
                    isOtherWeb={v.isOtherWeb}
                  >
                    {v.name}
                  </NavigationLink>
                ))}
              </MobileMenuBlock>
              <MobileMenuBlock isExpand={l.name === '产品服务' && isProductExpand}>
                {l.children.map((v, i) => (
                  <NavigationLink
                    key={i}
                    to={v.link}
                    title={v.alt || v.name}
                    isActive={location.pathname === v.link}
                    isOtherWeb={v.isOtherWeb}
                    target={v.isOtherWeb && '_blank'}
                  >
                    {v.name}
                  </NavigationLink>
                ))}
              </MobileMenuBlock>
              <MobileMenuBlock isExpand={l.name === 'CG社区' && isCgExpand}>
                {l.children.map((v, i) => (
                  <NavigationLink
                    key={i}
                    to={v.link}
                    title={v.alt || v.name}
                    isActive={location.pathname === v.link}
                    isOtherWeb={v.isOtherWeb}
                    target={v.isOtherWeb && '_blank'}
                  >
                    {v.name}
                  </NavigationLink>
                ))}
              </MobileMenuBlock>
              <MobileMenuBlock isExpand={l.name === '下载' && isDownloadExpand}>
                {l.children.map((v, i) => (
                  <NavigationLink
                    key={i}
                    to={v.link}
                    title={v.alt || v.name}
                    isActive={location.pathname === v.link}
                    isOtherWeb={v.isOtherWeb}
                    target={v.isOtherWeb && '_blank'}
                  >
                    {v.name}
                  </NavigationLink>
                ))}
              </MobileMenuBlock>
            </div>
          ) : (
            <NavigationLink
              key={i}
              to={l.link}
              title={l.alt || l.name}
              target={l.target}
              isActive={location.pathname === l.link}
              isOtherWeb={l.isOtherWeb}
              id={l.id}
              rel={l.rel}
              style={{ marginBottom: '0' }}
            >
              {l.name}
            </NavigationLink>
          ),
        )}
      </MobileMenuContainer>
    )
  }
}

export default MobileMenu
