import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color } from '../../theme'

const Hr = styled.hr`
  background-color: transparent;
  border: none;
  width: ${props => props.width};
  border-top: ${props => `1px ${props.shape} ${props.color}`};
`

const Divider = props => <Hr {...props} />

Divider.propTypes = {
  shape: PropTypes.oneOf(['dashed', 'solid', 'dotted']),
  color: PropTypes.string,
  width: PropTypes.string,
}
Divider.defaultProps = {
  shape: 'solid',
  color: `${color.divider}`,
  width: '100%',
}

export default Divider
