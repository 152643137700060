import React, { PureComponent } from 'react'
import { FriendLinksContainer } from './atoms'

class FriendLinks extends PureComponent {
  render() {
    const { friendLinks } = this.props
    return (
      <FriendLinksContainer>
        <p>友情链接： 友情链接申请，百度权重>=3，请加QQ：2162429318</p>
        {friendLinks &&
          friendLinks.map(({ name, link }, index) => (
            <a key={index} href={link} target='_blank' rel='noopener noreferrer'>
              {name}
            </a>
          ))}
      </FriendLinksContainer>
    )
  }
}

export default FriendLinks
