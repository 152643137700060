export default function isAdvertise() {
  return (
    window &&
    (window.location.search.includes('data_source') ||
      window.location.search.includes('utm_campaign') ||
      window.location.search.includes('utm_content') ||
      window.location.search.includes('utm_medium') ||
      window.location.search.includes('utm_source') ||
      window.location.search.includes('utm_term'))
  )
}
