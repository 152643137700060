import styled, { css } from 'styled-components'
import { lighten } from 'polished'

import NavigationLink from '../header/navigation-link'
import Flex from '../flex'
import { Column } from '../grid'
import { Anchor } from '../atoms'
import Media from '../../theme/media'
import transition from '../../theme/transition'
import { hideSMDown, hideSMUp } from '../../theme/mixin'
import { typography, spacing, color } from '../../theme/variables'

export const Container = styled.footer`
  color: #aaaaaa;
  background-color: #181818;
  margin-bottom: ${p => p.marginBottom || '0'};
`
export const PartnerWrap = styled(Flex)`
  font-size: ${typography.textThin};
  padding: ${spacing.large} 0;
  > p {
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    padding-top: ${spacing.small};
    flex-wrap: wrap;
    p.navigator-text {
      margin-left: 20px;
    }
  }
`
export const PostCardsContainer = styled.div`
  padding: 60px 0 30px 0;
  font-size: ${typography.textSmall};
  ${Media.lessThan(Media.small)} {
    position: relative;
    padding: 30px 0;
    text-align: center;
    > div > div:nth-child(2) {
      text-align: left;
      p,
      a {
        padding-left: 25px;
      }
    }
  }
`
export const DeusColumn = styled(Column)`
  ${hideSMDown};
`
export const IndustryColumn = styled(Column)`
  ${Media.lessThan(Media.small)} {
    display: ${props => (props.isShow ? 'block' : 'none')};
    padding: 25px !important;
  }
`
export const ContactUsColumn = styled(Column)`
  text-align: left;
  ${Media.lessThan(Media.small)} {
    position: absolute;
    left: 50%;
  }
`
export const VerticalDivider = styled.div`
  background-color: #333333;
  width: 1px;
  height: 100%;
  margin: 0 auto;
`
export const Title = styled.p`
  color: white;
  line-height: 2;
  margin-top: initial;
  font-size: ${typography.text};
  margin-bottom: ${spacing.small};
  &.hide-at-mobile {
    ${Media.lessThan(Media.small)} {
      visibility: hidden;
    }
  }
`
export const BlockAnchor = styled(Anchor)`
  display: block;
  line-height: 2;
`
export const QRCodeWrap = styled(Flex)`
  text-align: center;
  ${Media.lessThan(Media.small)} {
    justify-content: flex-start;
    padding-left: ${spacing.base};
    > div + div {
      margin-left: 4.6em;
    }
  }
`
export const FriendLinksContainer = styled.div`
  color: ${lighten(0.2, color.panel)};
  font-size: ${typography.textThin};
  margin-bottom: ${spacing.small};
  word-break: keep-all;
  a {
    display: inline-block;
    margin-right: ${spacing.small};
    :hover {
      color: ${color.primary};
    }
  }
  ${hideSMDown};
`
export const RayvisionLinksContainer = styled.div`
  color: ${lighten(0.2, color.panel)};
  font-size: ${typography.textSmall};
  margin-bottom: ${spacing.small};
  word-break: keep-all;
  a {
    display: inline-block;
    margin-right: ${spacing.small};
    color: ${lighten(0.2, color.panel)};
    :hover {
      color: ${color.primary};
    }
  }
  ${Media.lessThan(Media.small)} {
    text-align: center;
  }
`
export const ContactPhone = styled(Flex)`
  font-size: 14px;
  justify-content: flex-start;
  a {
    color: ${color.primary};
    margin-left: ${spacing.small};
    ${hideSMUp};
  }
`
const LinkStyle = css`
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
  transition: ${transition('color')};
  padding: 0;
  :hover {
    color: ${color.primary};
  }
`
//@see https://github.com/styled-components/styled-components/issues/1302#issuecomment-342788554
export const NavigatorAnchor = styled(NavigationLink)`
  && {
    ${LinkStyle}
    ${PartnerWrap} & {
      font-size: ${typography.textThin} !important;
    }
  }
`
//@see https://github.com/styled-components/styled-components/issues/1816#issuecomment-549658315
export const NavigationAnchor = styled(NavigationLink)`
  && {
    ${LinkStyle}
    display: block;
    line-height: 2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }
  .navigation-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 30px;
  }
  &.share-mobile {
    display: none;
  }
  &.share {
    display: block;
  }
  ${Media.lessThan(Media.small)} {
    &.share {
      display: none;
    }
    &.share-mobile {
      display: block;
    }
  }
`

export const LicenceText = styled.span`
  display: inline-block;
  margin-left: 20px;
`
