import React, { Component } from 'react'
import { EventBus } from '../../service'
import { ContactbarContainer, ContactItem, IconContainer, PopoverWrapper } from './atoms'
import FeedbackModal from './feedbackModal'
import Feedback from './icon/Feedback'
import FeedbackHover from './icon/FeedbackHover'
import Kefu from './icon/Kefu'
import KefuHover from './icon/KefuHover'
import PhoneIcon from './icon/Phone'
import PhoneHover from './icon/PhoneHover'
import Qr from './icon/Qr'
import QrHover from './icon/QrHover'
import Top from './icon/Top'
import TopHover from './icon/TopHover'
import WechatIcon from './icon/Wechat'
import WechatHover from './icon/WechatHover'
import Wechat from './images/wechat-qrcode.png'
import ThanksModal from './thanksModal'

class Contactbar extends Component {
  state = {
    show: false,
    thanksShow: false,
    isSupport: false,
  }
  onClick = () => {
    this.setState({ ...this.state, show: true })
  }

  backToTop = e => {
    e.stopPropagation()
    const result = document.querySelector('main')
    window.location.hash = ''
    result.scrollIntoView()
    EventBus.emit('backToTop')
    if (this.props.onClick) {
      this.props.onClick()
    }
  }
  componentDidMount() {
    if (typeof window !== 'undefined')
      this.setState({
        ...this.state,
        isSupport: window.location.pathname.indexOf('/support-new/') !== -1,
      })
  }
  render() {
    return (
      <>
        <ContactbarContainer isSupport={this.state.isSupport}>
          <ContactItem>
            <IconContainer>
              <a
                href={'https://wpa1.qq.com/SV5t0IWb?_type=wpa&qidian=true'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Kefu data-icon />
                <KefuHover data-icon-hover />
              </a>
            </IconContainer>

            <PopoverWrapper className='common-popover-style'>
              <a
                href={'https://wpa1.qq.com/SV5t0IWb?_type=wpa&qidian=true'}
                rel='noopener noreferrer'
                target='_blank'
              >
                网页咨询
              </a>
            </PopoverWrapper>
          </ContactItem>
          <ContactItem>
            <IconContainer>
              <a
                href={'https://wpa1.qq.com/ujoglPGq?_type=wpa&qidian=true'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <WechatIcon data-icon />
                <WechatHover data-icon-hover />
              </a>
            </IconContainer>

            <PopoverWrapper className='common-popover-style'>
              <a
                href={'https://wpa1.qq.com/ujoglPGq?_type=wpa&qidian=true'}
                rel='noopener noreferrer'
                target='_blank'
              >
                微信咨询
              </a>
            </PopoverWrapper>
          </ContactItem>
          <ContactItem>
            <IconContainer>
              <PhoneIcon data-icon />
              <PhoneHover data-icon-hover />
            </IconContainer>

            <PopoverWrapper className='qr-popover-style'>
              <div className='qr-title'>24小时全国服务热线</div>
              <div className='qr-phone-number'>400-003-4560</div>
            </PopoverWrapper>
          </ContactItem>
          <ContactItem>
            <IconContainer>
              <Qr data-icon />
              <QrHover data-icon-hover />
            </IconContainer>
            <PopoverWrapper className='wechat-popover-style'>
              <img src={Wechat} alt='wechat' />
            </PopoverWrapper>
          </ContactItem>
          {this.state.isSupport && (
            <ContactItem>
              <IconContainer onClick={this.onClick}>
                <Feedback data-icon />
                <FeedbackHover data-icon-hover />
              </IconContainer>
              <PopoverWrapper className='common-popover-style'>文档反馈</PopoverWrapper>
            </ContactItem>
          )}
          <ContactItem onClick={this.backToTop}>
            <IconContainer>
              <Top data-icon />
              <TopHover data-icon-hover />
            </IconContainer>
          </ContactItem>
        </ContactbarContainer>
        <FeedbackModal
          visible={this.state.show}
          onClose={() => {
            this.setState({ show: false })
          }}
          onThanksChange={visible => this.setState({ ...this.state, thanksShow: visible })}
        />
        <ThanksModal
          visible={this.state.thanksShow}
          onClose={() => this.setState({ ...this.state, thanksShow: false })}
        />
      </>
    )
  }
}
export default Contactbar
