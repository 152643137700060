import styled from 'styled-components'
import { color, typography } from '../theme'
import transition from '../theme/transition'
import Media from '../theme/media'

export const H1 = styled.h1`
  margin: 0;
  color: white;
  font-size: ${typography.h1};
`
export const H2 = styled.h2`
  margin: 0;
  color: white;
  font-size: ${typography.h2};
`
export const H3 = styled.h3`
  margin: 0;
  color: white;
  font-size: ${typography.h3};
`
export const H4 = styled.h4`
  margin: 0;
  color: white;
  font-size: ${typography.h4};
`
export const Paragraph = styled.p`
  color: ${color.text};
  font-size: ${typography.text};
`
export const Prompt = styled.span`
  color: ${color.prompt};
  font-size: ${typography.textSmall};
`
export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: ${color.divider};
`
export const Anchor = styled.a`
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
  transition: ${transition('color')};
  :hover {
    color: ${color.primary};
  }
`
export const Section = styled.section`
  padding: 100px 0;
  ${Media.lessThan(Media.small)} {
    padding: 40px 0;
  }
`
export const PriceSubSection = styled.section`
  padding: 100px 0;
  background-color: #181818;
  ${Media.lessThan(Media.small)} {
    padding: 40px 0;
  }
`
export const LightSection = styled(Section)`
  background-color: ${color.panel};
`
export const SubLightSection = styled(Section)`
  background-color: ${color.lightCaculate};
`
export const TreasureSection = styled(Section)`
  background-color: ${color.blackPlus};
  ${Media.lessThan(Media.small)} {
    height: 1807px;
  }
`
export const SectionTitle = styled(H2)`
  text-align: center;
  margin: 0 0 60px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 30px;
    font-size: 18px; /* fuck design */
  }
`

export const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: calc(60% - 139px);
  right: 20px;
  z-index: 10;
  width: auto;
  height: 140px;
  display: flex;
  align-items: center;
  .close-img {
    position: absolute;
    right: -8px;
    top: -8px;
    cursor: pointer;
  }
  .activity-img {
    width: 100px;
    cursor: pointer;
    top: calc(60% - 139px);
    position: fixed;
    right: 20px;
  }
  .activity-content {
    display: flex;
    flex-direction: column;
    margin-right: 110px;
    gap: 12px;
    img {
      width: 260px;
      height: 100px;
    }
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
