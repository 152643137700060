import styled from 'styled-components'

export const ElasticSearchResultContainer = styled.div`
  width: 100%;
`
export const LoadingContainer = styled.div`
  display: flex;
  height: 100px;
  width: 800px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
