import React from 'react'

const SvgProduct04 = props => (
  <svg viewBox='0 0 22 24.01' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M11 0L0 6v12l11 6 11-6V6zm8.9 8.52V17l-7.84 4.36v-8.49zm-9 2.79L2.52 6.76l8.35-4.56 8.35 4.56zm-.7 1.59v8.45L2.1 17V8.52z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgProduct04
