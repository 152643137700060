import * as React from 'react'
const SVGComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='25px' height='24px' {...props}>
    <path
      fillRule='evenodd'
      fill='rgb(19, 203, 117)'
      d='M24.169,12.703 C23.573,14.334 22.653,15.987 21.506,17.486 C20.180,19.218 18.627,20.649 16.887,21.740 C14.860,23.13 12.648,23.770 10.312,23.989 C10.252,23.995 10.181,24.0 10.114,24.0 C9.119,24.0 8.189,23.325 7.585,22.758 C6.700,21.944 5.748,20.671 5.748,19.557 C5.748,19.104 5.920,18.636 6.274,18.127 C6.494,17.810 6.782,17.477 7.155,17.111 C7.843,16.437 8.694,15.790 9.432,15.376 C9.855,15.140 11.138,14.421 12.185,15.84 C12.790,15.464 13.181,16.133 13.596,16.842 C13.748,17.104 13.896,17.358 14.29,17.553 C14.146,17.487 14.296,17.394 14.486,17.263 C15.47,16.877 15.700,16.332 16.324,15.728 C17.3,15.73 17.577,14.421 17.985,13.842 C18.213,13.520 18.344,13.281 18.421,13.116 C18.287,13.26 18.136,12.935 17.988,12.847 C17.316,12.442 16.500,11.949 16.73,11.136 C15.603,10.249 15.915,9.137 17.85,7.533 C17.810,6.540 18.687,5.601 19.178,5.186 C19.540,4.879 20.324,4.427 21.483,4.865 C22.362,5.196 23.132,5.930 23.623,6.487 C24.12,6.930 24.681,7.782 24.803,8.535 C24.979,9.642 24.760,11.83 24.169,12.703 ZM17.138,3.787 C15.562,2.467 13.564,1.740 11.513,1.740 C6.650,1.740 2.694,5.736 2.694,10.649 C2.694,12.707 3.369,14.640 4.645,16.239 C4.944,16.613 4.886,17.161 4.516,17.463 C4.356,17.593 4.165,17.656 3.975,17.656 C3.723,17.656 3.474,17.546 3.304,17.332 C1.799,15.448 0.970,13.75 0.970,10.650 C0.970,4.777 5.700,0.0 11.513,0.0 C13.965,0.0 16.353,0.869 18.238,2.446 C18.604,2.753 18.655,3.302 18.351,3.672 C18.48,4.43 17.505,4.93 17.138,3.787 Z'
    />
    <text
      kerning='auto'
      fontFamily='Adobe Heiti Std'
      fill='rgb(0, 0, 0)'
      fontSize='12px'
      x='4px'
      y='14px'
    >
      <tspan fontSize='12px' fontFamily='Roboto' fill='#13CB75'>
        &#50;&#52;
      </tspan>
    </text>
  </svg>
)
export default SVGComponent
