import React from 'react'
//import Wordcloud from './wordcloud2'
import maskImg from './images/mask.png'
import { BannerContainer, WordContainer, Word } from './atom'

const OriginCanvasWidth = 1200
const OriginCanvasHeight = 250
const scale = 1.5
let originalRectangle = {}
const originalRadius = 80
let centerPoint = { x: 0, y: 0 }
class WordCloudCanvas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canvasWidth: OriginCanvasWidth,
      canvasHeight: OriginCanvasHeight,
      maxFontSize: Math.floor(OriginCanvasWidth / 70),
      minFontSize: Math.floor(OriginCanvasWidth / 125),
      showWordCloudCanvas: true,
      imgSrc: '',
    }
    this.canvasRef = null
    this.canvasScaleRef = null
  }
  setCanvasSize = () => {
    let viewportWidth = document.body.clientWidth
    if (viewportWidth < 780) {
      this.setState({
        showWordCloudCanvas: false,
      })
      return false
    } else if (viewportWidth > OriginCanvasWidth) {
      this.setState({
        canvasWidth: OriginCanvasWidth,
        canvasHeight: OriginCanvasHeight,
        maxFontSize: Math.floor(OriginCanvasWidth / 70),
        minFontSize: Math.floor(OriginCanvasWidth / 105),
        showWordCloudCanvas: true,
      })
      return true
    } else {
      let canvasHeight = (viewportWidth * OriginCanvasHeight) / OriginCanvasWidth
      this.setState({
        canvasWidth: viewportWidth,
        canvasHeight: canvasHeight,
        maxFontSize: Math.floor(viewportWidth / 70),
        minFontSize: Math.floor(viewportWidth / 105),
        showWordCloudCanvas: true,
      })
      return true
    }
  }
  componentDidMount() {
    //init wordCloudArray
    if (!this.setCanvasSize()) {
      return
    }
    const wordCloudArray = this.props.wordCloudArray.map((el, index) => {
      return [
        el.name,
        index + 1,
        this.props.pathPrefix ? `${this.props.pathPrefix}/${el.slug}/` : `/${el.slug}/`,
      ]
    })
    var Wordcloud = require('./wordcloud2')
    var wordCloudCanvas = this.canvasRef
    var maskCanvas
    var img = new Image()
    img.src = maskImg
    img.onload = () => {
      window.URL.revokeObjectURL(maskImg)

      maskCanvas = document.createElement('canvas')
      maskCanvas.width = img.width
      maskCanvas.height = img.height

      var ctx = maskCanvas.getContext('2d')
      ctx.drawImage(img, 0, 0, img.width, img.height)

      var imageData = ctx.getImageData(0, 0, maskCanvas.width, maskCanvas.height)
      var newImageData = ctx.createImageData(imageData)

      for (let i = 0; i < imageData.data.length; i += 4) {
        var tone = imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]
        var alpha = imageData.data[i + 3]

        if (alpha < 128 || tone > 128 * 3) {
          // Area not to draw
          newImageData.data[i] = newImageData.data[i + 1] = newImageData.data[i + 2] = 255
          newImageData.data[i + 3] = 0
        } else {
          // Area to draw
          newImageData.data[i] = newImageData.data[i + 1] = newImageData.data[i + 2] = 0
          newImageData.data[i + 3] = 255
        }
      }

      ctx.putImageData(newImageData, 0, 0)

      var bctx = document.createElement('canvas').getContext('2d')

      bctx.fillStyle = '#191919'
      bctx.fillRect(0, 0, 1, 1)
      var bgPixel = bctx.getImageData(0, 0, 1, 1).data

      var maskCanvasScaled = document.createElement('canvas')
      maskCanvasScaled.width = wordCloudCanvas.width
      maskCanvasScaled.height = wordCloudCanvas.height
      var cctx = maskCanvasScaled.getContext('2d')

      cctx.drawImage(
        maskCanvas,
        0,
        0,
        maskCanvas.width,
        maskCanvas.height,
        0,
        0,
        maskCanvasScaled.width,
        maskCanvasScaled.height,
      )

      var imageData2 = cctx.getImageData(0, 0, wordCloudCanvas.width, wordCloudCanvas.height)
      var newImageData2 = cctx.createImageData(imageData2)
      for (let i = 0; i < imageData2.data.length; i += 4) {
        if (imageData2.data[i + 3] > 128) {
          newImageData2.data[i] = bgPixel[0]
          newImageData2.data[i + 1] = bgPixel[1]
          newImageData2.data[i + 2] = bgPixel[2]
          newImageData2.data[i + 3] = bgPixel[3]
        } else {
          // This color must not be the same w/ the bgPixel.
          newImageData2.data[i] = bgPixel[0]
          newImageData2.data[i + 1] = bgPixel[1]
          newImageData2.data[i + 2] = bgPixel[2]
          newImageData2.data[i + 3] = bgPixel[3] ? bgPixel[3] - 1 : 0
        }
      }

      cctx.putImageData(newImageData2, 0, 0)

      cctx = wordCloudCanvas.getContext('2d')
      cctx.drawImage(maskCanvasScaled, 0, 0)

      maskCanvasScaled = ctx = cctx = imageData = newImageData = imageData2 = newImageData2 = bctx = bgPixel = undefined

      let { maxFontSize, minFontSize } = this.state
      let that = this
      Wordcloud.default(wordCloudCanvas, {
        list: wordCloudArray,
        color: '#0bcaf4',
        backgroundColor: '#191919',
        rotateRatio: 0,
        clearCanvas: false,
        gridSize: 10,
        weightFactor: function(size) {
          return (maxFontSize * size - (maxFontSize - minFontSize)) / size
        },
        click: function(item) {
          window.open(item[2], '_blank')
        },
        hover: function(item, dimension, loadingStop) {
          if (item) {
            wordCloudCanvas.style.cursor = 'pointer'
            if (that.state.imgSrc === '' && loadingStop) {
              that.setState({
                imgSrc: that.canvasRef
                  .toDataURL('image/png')
                  .replace('image/png', 'image/octet-stream'),
              })
              that.initImage()
            }
          } else {
            wordCloudCanvas.style.cursor = 'default'
          }
        },
      })
    }
  }
  initImage = () => {
    let that = this
    that.image2 = new Image()
    that.image2.src = that.state.imgSrc

    that.image2.onload = function() {
      that.canvasRef
        .getContext('2d')
        .drawImage(that.image2, 0, 0, that.canvasRef.width, that.canvasRef.height)
      that.moveEvt()
    }
  }
  moveEvt = () => {
    let that = this
    that.canvasRef.onmousemove = function(e) {
      that.showImage(e)
    }

    that.canvasRef.onmouseup = function(e) {
      that.hideImage(e)
    }

    that.canvasRef.onmouseout = function(e) {
      that.hideImage(e)
    }
  }

  calOriginalRectangle = (x, y) => {
    originalRectangle.x = x - originalRadius
    originalRectangle.y = y - originalRadius
    originalRectangle.width = originalRadius * 2
    originalRectangle.height = originalRadius * 2
  }
  drawMagnifyingGlass = () => {
    let context = this.canvasRef.getContext('2d')
    let scaleGlassRectangle = {
      x: centerPoint.x - (originalRectangle.width * scale) / 2,
      y: centerPoint.y - (originalRectangle.height * scale) / 2,
      width: originalRectangle.width * scale,
      height: originalRectangle.height * scale,
    }
    context.save()
    context.beginPath()
    context.arc(centerPoint.x, centerPoint.y, originalRadius, 0, Math.PI * 2, false)
    context.clip()

    context.drawImage(
      this.canvasScaleRef,
      originalRectangle.x,
      originalRectangle.y,
      originalRectangle.width,
      originalRectangle.height,
      scaleGlassRectangle.x,
      scaleGlassRectangle.y,
      scaleGlassRectangle.width,
      scaleGlassRectangle.height,
    )
    context.restore()
    context.beginPath()
    context.strokeStyle = '#9eddf1'
    context.lineWidth = 3
    context.arc(centerPoint.x, centerPoint.y, originalRadius, 0, Math.PI * 2, false)
    context.stroke()
  }
  showImage = e => {
    e.preventDefault()
    centerPoint.x = e.offsetX
    centerPoint.y = e.offsetY

    this.image = new Image()
    this.image.src = this.state.imgSrc
    this.canvasRef.getContext('2d').clearRect(0, 0, this.canvasRef.width, this.canvasRef.height)
    this.canvasRef
      .getContext('2d')
      .drawImage(this.image, 0, 0, this.canvasRef.width, this.canvasRef.height)
    this.calOriginalRectangle(e.offsetX, e.offsetY)
    this.drawMagnifyingGlass()
  }
  hideImage = e => {
    e.preventDefault()
    this.image = new Image()
    this.image.src = this.state.imgSrc
    this.canvasRef.getContext('2d').clearRect(0, 0, this.canvasRef.width, this.canvasRef.height)
    this.canvasRef
      .getContext('2d')
      .drawImage(this.image, 0, 0, this.canvasRef.width, this.canvasRef.height)
  }
  setCanvasRef = ref => {
    this.canvasRef = ref
  }
  setScaleCanvasRef = ref => {
    this.canvasScaleRef = ref
  }
  render() {
    let { wordCloudArray } = this.props
    let { showWordCloudCanvas } = this.state
    return (
      <BannerContainer>
        {showWordCloudCanvas ? (
          <canvas
            id='word_cloud_canvas'
            height={this.state.canvasHeight}
            width={this.state.canvasWidth}
            ref={this.setCanvasRef}
          />
        ) : (
          <WordContainer>
            {wordCloudArray.map((el, index) => {
              return (
                <Word herf={el.slug} key={index}>
                  {el.name}
                </Word>
              )
            })}
          </WordContainer>
        )}
        <img
          src={this.state.imgSrc}
          ref={this.setScaleCanvasRef}
          alt=''
          style={{ display: 'none' }}
        />
      </BannerContainer>
    )
  }
}
export default WordCloudCanvas
