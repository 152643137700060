import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import PaginationButton from './pagination-button'

class PaginationPageGroup extends Component {
  constructor(props) {
    super(props)
    this.maxChunk = 10
  }

  getPages() {
    const { total, currentPage } = this.props
    if (total <= 10) {
      return Array.from({ length: total }, (x, i) => i + 1)
    } else {
      if (currentPage >= 9) {
        if (currentPage > total - 4) {
          return Array.from({ length: 7 }, (x, i) => i + total - 8)
        }
        return Array.from({ length: 7 }, (x, i) => i + currentPage - 3)
      } else {
        return Array.from({ length: 7 }, (x, i) => i + 3)
      }
    }
  }
  clickPageNum() {
    const { handleCurrentPage } = this.props
    if (handleCurrentPage) {
      handleCurrentPage()
    }
  }

  render() {
    const { total, currentPage } = this.props
    this.pages = this.getPages()
    return (
      <Fragment>
        {total <= this.maxChunk ? (
          <Fragment>
            {this.pages.map((number, index) => (
              <PaginationButton
                key={index}
                total={total}
                active={currentPage === number}
                onClick={this.clickPageNum()}
              >
                {number}
              </PaginationButton>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            <PaginationButton total={total} active={currentPage === 1}>
              1
            </PaginationButton>
            {currentPage >= 9 ? (
              <PaginationButton isBan={true}>...</PaginationButton>
            ) : (
              <PaginationButton total={total} active={currentPage === 2}>
                2
              </PaginationButton>
            )}
            {this.pages.map((number, index) => (
              <PaginationButton key={index} total={total} active={currentPage === number}>
                {number}
              </PaginationButton>
            ))}
            {currentPage < total - 4 ? (
              <PaginationButton isBan={true}>...</PaginationButton>
            ) : (
              <PaginationButton total={total} active={currentPage === total - 1}>
                {total - 1}
              </PaginationButton>
            )}
            <PaginationButton total={total} active={currentPage === total}>
              {total}
            </PaginationButton>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

PaginationPageGroup.propTypes = {
  total: PropTypes.number,
  currentPage: PropTypes.number,
}

export default PaginationPageGroup
