import * as React from 'react'
const SVGComponent = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    // xmlns:xlink='http://www.w3.org/1999/xlink'
    width='24px'
    height='24px'
    {...props}
  >
    <path
      fillRule='evenodd'
      fill='rgb(19, 203, 117)'
      d='M23.26,15.120 L21.933,15.120 C21.412,19.766 17.696,23.458 13.40,23.942 C13.15,23.947 12.989,23.948 12.964,23.953 C12.956,23.954 12.949,23.954 12.942,23.955 C12.798,23.980 12.652,23.999 12.502,23.999 L11.497,23.999 C10.64,23.999 8.899,22.834 8.899,21.402 C8.899,19.969 10.64,18.804 11.497,18.804 L12.502,18.804 C13.925,18.804 15.82,19.955 15.96,21.375 C17.974,20.162 20.0,17.313 20.0,13.999 L20.0,10.0 C20.0,5.588 16.411,2.0 12.0,2.0 C7.589,2.0 4.0,5.588 4.0,10.0 L4.0,15.120 L2.0,15.120 L2.0,15.120 L0.973,15.120 C0.435,15.120 0.0,14.690 0.0,14.160 L0.0,9.840 C0.0,9.309 0.435,8.880 0.973,8.880 L2.66,8.880 C2.626,3.891 6.865,0.0 12.0,0.0 C17.134,0.0 21.374,3.891 21.933,8.880 L23.26,8.880 C23.564,8.880 24.0,9.309 24.0,9.840 L24.0,14.160 C24.0,14.690 23.564,15.120 23.26,15.120 ZM13.99,21.402 C13.99,21.73 12.831,20.804 12.502,20.804 L11.497,20.804 C11.168,20.804 10.899,21.73 10.899,21.402 C10.899,21.731 11.168,21.999 11.497,21.999 L12.0,21.999 C12.225,21.999 12.448,21.984 12.669,21.966 C12.915,21.892 13.99,21.672 13.99,21.402 Z'
    />
    <path
      fillRule='evenodd'
      fill='rgb(19, 203, 117)'
      d='M14.666,9.474 C13.930,9.474 13.333,8.918 13.333,8.232 C13.333,7.546 13.930,6.990 14.666,6.990 C15.403,6.990 16.0,7.546 16.0,8.232 C16.0,8.918 15.403,9.474 14.666,9.474 ZM13.661,12.394 C13.767,11.987 14.213,11.741 14.642,11.834 C15.79,11.933 15.348,12.342 15.243,12.748 C14.906,14.49 13.474,14.994 11.839,14.994 C10.204,14.994 8.773,14.49 8.435,12.749 C8.330,12.342 8.598,11.933 9.34,11.835 C9.469,11.738 9.910,11.987 10.15,12.393 C10.177,13.12 10.961,13.479 11.839,13.479 C12.718,13.479 13.501,13.13 13.661,12.394 ZM9.333,9.474 C8.596,9.474 7.999,8.918 7.999,8.232 C7.999,7.546 8.596,6.990 9.333,6.990 C10.69,6.990 10.666,7.546 10.666,8.232 C10.666,8.918 10.69,9.474 9.333,9.474 Z'
    />
  </svg>
)
export default SVGComponent
