import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

class Highlight extends Component {
  stopWordsReg = /[\s,.?!\-_()[\]~`;:"'|^&<>/*\\]/
  filterReg = /[^:,.!?;]*\*\*[\w\s]+\*\*[^:,.!?;]*/g

  addHightlightTag() {
    const { htmlFragment, keywords, type } = this.props
    let newHtmlFragment = htmlFragment
    if (keywords && newHtmlFragment) {
      const keys = [...new Set(keywords.split(this.stopWordsReg).filter(key => key !== ''))]
      const keywordsStr = keys.join('|')
      const reg = new RegExp(`${keywordsStr}`, 'gi')
      newHtmlFragment = newHtmlFragment
        .replace(reg, match => `**${match}**`)
        .replace(/\*\*{2,}/g, '')
    }
    if (type === 'partial' && newHtmlFragment && newHtmlFragment.length > 100) {
      const matchFragment = newHtmlFragment.match(this.filterReg)
      if (matchFragment && matchFragment.length > 1) {
        let partialFragment = ''
        matchFragment.forEach(item => {
          partialFragment += item + '...'
        })
        newHtmlFragment = partialFragment
      }
    }
    return newHtmlFragment
  }

  render() {
    return <ReactMarkdown source={this.addHightlightTag()} escapeHtml={false} />
  }
}

Highlight.propTypes = {
  type: PropTypes.oneOf(['whole', 'partial']),
  keywords: PropTypes.string,
  htmlFragment: PropTypes.string,
}
Highlight.defaultProps = {
  type: 'whole',
}

export default Highlight
