import React, { createContext } from 'react'
import Helmet from 'react-helmet'
import { globalClickCallback, DataBury } from 'renderbus-data-bury'
import { DataCollector } from '@rayvision/data-collect'

import Header from '../components/header'
import Footer from '../components/footer'
import ContactBar from '../components/contact-bar'
import GlobalStyle from '../theme/global-style'
import { API, Axios, Cookie } from '../utils'
import { UserAuthorizationService } from '../service'
import { PageViewMap } from '../constant/page-view'
import { isTest, isDev, isAdvertise } from '@renderbus/common/service'
import { EventBus } from '@renderbus/common/service'
import YearEndImg from '../images/year-end.png'
import AnimationYardEndImg from '../images/animation-year-end.png'
import CloseImg from '../images/close.png'
import { Sidebar } from './atoms'
var moment = require('moment')
export const IsInMainContext = createContext(false)
class Layout extends React.PureComponent {
  state = {
    userName: '',
    happyNewYear: false,
    userId: '',
    showImages: false,
    showSideBar: true,
  }

  renderbusGlobalClickCallback(e) {
    globalClickCallback(e, element => {
      if (!element) return
      const index = element.getAttribute('data-index')
      return PageViewMap[index]
    })
  }

  handleGlobalClick() {
    window.document.addEventListener('click', this.renderbusGlobalClickCallback, { capture: true })
    window.document.addEventListener('visibilitychange', this.handlePageChange.bind(this), {
      capture: true,
    })
  }
  getUserLoginInformation() {
    const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)
    // instance.get('/api/rendering/wechat/qrcode/showPictureqrcode')
    // instance.get('/api/sso/thirdParty/getToken')
    // instance.post('/api/sso/register/checkVerificationCode')
    // const ssoAxios = new Axios('https://sso-pre.renderbus.com')
    // ssoAxios.post('/api/sso/register/checkVerificationCode')
    instance.post(API.auth.queryUser).then(res => {
      if (res && res.code === 200) {
        UserAuthorizationService.setUserName(res.data.userName)
        UserAuthorizationService.setUserId(res.data.userId)
        UserAuthorizationService.setIsPictureUser(`${res.data.businessType === 0}`)
        EventBus.emit('loginStatusUpdate')
        // for data bury
        if (typeof window !== 'undefined') {
          localStorage.setItem('userId', res.data.userId)
        }
        this.setState({
          userName: UserAuthorizationService.getUserName(),
          userId: res.data.userId,
        })
      } else {
        UserAuthorizationService.removeUserName()
        UserAuthorizationService.removeIsPictureUser()
        // for data bury
        if (typeof window !== 'undefined') {
          localStorage.setItem('userId', '')
        }
        this.setState({
          userName: '',
        })
      }
    })
  }
  handlePageChange() {
    if (document.hidden) return
    this.getUserLoginInformation()
  }
  componentDidMount() {
    const excludePageList = ['/architectural-rendering.html']
    const isExcludePage = window && excludePageList.includes(window.location.pathname)
    const baseUrl = isDev()
      ? '/'
      : isTest()
      ? 'https://task-pre.renderbus.com/'
      : 'https://task.renderbus.com/'
    DataCollector.init({
      app_id: 20002822,
      enable_stay_duration: true,
      enable_debug: Boolean(localStorage.getItem('huoshan_init_enable_debug')),
      channel_domain: `${baseUrl}api/rendering/buriedpointdata/collector`,
    }).then(self => {
      if (isAdvertise() && !isExcludePage) {
        const timestamp = +new Date()
        Cookie.setCookie('huoshan_adv_timestamp', String(timestamp))
        self
          .emit('predefinePageView', {
            immediate_to_huoshan: 1,
            timestamp,
            comefrom: Cookie.getCookie('comefrom'),
          })
          .emit('register_event', {
            immediate_to_huoshan: 1,
            timestamp,
            comefrom: Cookie.getCookie('comefrom'),
          })
      }
    })
    DataBury.host = 'https://task-pre.renderbus.com'
    this.handleGlobalClick()
    this.getUserLoginInformation()
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.renderbusGlobalClickCallback)
    window.document.removeEventListener('visibilitychange', this.handlePageChange)
  }

  render() {
    const {
      children,
      location: _location,
      isInMain,
      isWithoutFooter,
      topThreeShare,
      friendLinks,
      seoTitle,
      pubDate,
      upDate,
      className,
      marginBottom,
    } = this.props
    const { showImages, showSideBar } = this.state
    const location = _location || (typeof window !== 'undefined' ? window.location : {})
    const pathname = location.pathname || ''
    var baiduLdJson = {
      '@context': 'https://ziyuan.baidu.com/contexts/cambrian.jsonld',
      '@id': `${
        isTest() ? 'http://pre.renderbus.com:8081' : 'https://www.renderbus.com'
      }${pathname}`,
      title: seoTitle,
    }
    if (pubDate) {
      baiduLdJson = {
        ...baiduLdJson,
        ...{ pubDate: moment(pubDate).format('YYYY-MM-DDTHH:mm:ss') },
      }
    }
    baiduLdJson = {
      ...baiduLdJson,
      ...{
        upDate: moment(upDate)
          .add(8, 'h')
          .format('YYYY-MM-DDTHH:mm:ss'),
      },
    }
    return (
      <IsInMainContext.Provider value={isInMain}>
        <Helmet>
          <script>
            {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
              // 360统计代码
              (function(b,a,e,h,f,c,g,s){b[h]=b[h]||function(){(b[h].c=b[h].c||[]).push(arguments)};
b[h].s=!!c;g=a.getElementsByTagName(e)[0];s=a.createElement(e);
s.src="//s.union.360.cn/"+f+".js";s.defer=!0;s.async=!0;g.parentNode.insertBefore(s,g)
})(window,document,"script","_qha",359342,false);
              // clarity 热力图
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "k4r0i3gxzc");
            `}
          </script>
          {seoTitle && upDate && (
            <script type='application/ld+json'>{JSON.stringify(baiduLdJson)}</script>
          )}
        </Helmet>
        <Header location={location} userName={this.state.userName} />
        <main>{children}</main>
        {showSideBar && (
          <Sidebar
            onMouseEnter={() => this.setState({ showImages: true })}
            onMouseLeave={() => this.setState({ showImages: false })}
          >
            <img
              src={CloseImg}
              className='close-img'
              alt=''
              onClick={() => this.setState({ showSideBar: false })}
            />
            <img src={YearEndImg} className='activity-img' alt='' />
            {showImages && (
              <div className='activity-content'>
                <a href='/ysdhnzhd.html?utm_source=activity' rel='nofollow'>
                  <img src={AnimationYardEndImg} alt='' />
                </a>
              </div>
            )}
          </Sidebar>
        )}
        {pathname !== '/aspera.html' && <ContactBar />}
        {!isWithoutFooter && (
          <Footer
            info={topThreeShare}
            friendLinks={friendLinks}
            className={className}
            marginBottom={marginBottom}
          />
        )}
        <GlobalStyle></GlobalStyle>
      </IsInMainContext.Provider>
    )
  }
}

export default Layout
