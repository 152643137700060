import { sessionStorage } from '../utils'

const storagekey = {
  userName: 'userName',
  userId: 'userId',
  isPictureUser: 'isPictureUser',
}

export default class UserAuthorizationService {
  static getUserName() {
    return sessionStorage.get(storagekey.userName)
  }
  static getUserId() {
    return sessionStorage.get(storagekey.userId)
  }
  static setUserName(userName) {
    sessionStorage.set(storagekey.userName, userName)
  }
  static setUserId(userId) {
    sessionStorage.set(storagekey.userId, userId)
  }
  static removeUserName() {
    sessionStorage.remove(storagekey.userName)
  }
  static setIsPictureUser(isPictureUser) {
    sessionStorage.set(storagekey.isPictureUser, isPictureUser)
  }
  static removeIsPictureUser() {
    sessionStorage.remove(storagekey.isPictureUser)
  }
  static getoginUserType() {
    return sessionStorage.get(storagekey.isPictureUser)
  }
  static isLogined() {
    return !!UserAuthorizationService.getUserName()
  }
}
