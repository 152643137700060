import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width={52} height={52} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M52 26C52 11.642 40.358 0 26 0S0 11.642 0 26s11.642 26 26 26 26-11.642 26-26zm-4.41 0c0 11.92-9.67 21.59-21.59 21.59S4.41 37.92 4.41 26 14.08 4.41 26 4.41 47.59 14.08 47.59 26zm-10.366-8.49a.462.462 0 00-.371-.738H34.13c-.592 0-1.155.285-1.503.772l-9.124 12.652-4.132-5.734a1.856 1.856 0 00-1.503-.772h-2.722a.466.466 0 00-.377.737l7.231 10.029a1.846 1.846 0 003 0l12.223-16.947z'
        fill='#13cb75'
      />
    </svg>
  )
}

export default SvgComponent
