/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback } from 'react'
import 'intersection-observer'
// intersection polyfill

export default function useInViewport(options = {}) {
  const [isInViewport, setIsInViewport] = useState(null)
  const { root = null, threshold = 0, rootMargin = '0px', onEnter, onLeave } = options
  const targetRef = useRef(null)
  const setTargetRef = useCallback(
    node => {
      targetRef.current = node
    },
    [targetRef],
  )

  useEffect(() => {
    const target = targetRef.current
    const callback = entries => {
      const targetEntry = entries.filter(entry => entry.target === target)[0]
      if (targetEntry) {
        const { isIntersecting } = targetEntry
        setIsInViewport(isIntersecting)
        if (isIntersecting) {
          onEnter && onEnter(entries)
        } else {
          onLeave && onLeave(entries)
        }
      }
    }
    const intersectionObserver = new IntersectionObserver(callback, { root, rootMargin, threshold })
    intersectionObserver.observe(target)
    return () => intersectionObserver.unobserve(target)
  }, [targetRef])

  return [isInViewport, setTargetRef]
}
