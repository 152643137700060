import axios from 'axios'
class Axios {
  constructor(host) {
    this.host = host
    this.instance = axios.create({
      withCredentials: true,
      baseURL: process.env.NODE_ENV === 'development' ? '/' : host,
    })
  }
  get(url) {
    return this.instance
      .get(url)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error.message)
      })
  }
  post(url, params = {}) {
    return this.instance
      .post(url, params)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error.message)
      })
  }
}

export default Axios
