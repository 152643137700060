import React, { PureComponent } from 'react'
import { RayvisionLinksContainer } from './atoms'

class RayvisionLinks extends PureComponent {
  render() {
    const rayvisionLinks = [
      {
        name: '3DCAT实时渲染云',
        link: 'https://www.3dcat.live/?renderbus',
      },
      {
        name: '镭速传输',
        link: 'https://www.raysync.cn/?renderbus',
      },
      {
        name: '青椒云',
        link: 'https://qingjiaocloud.com/?renderbus',
      },
      // {
      //   name: 'RayLink远程控制软件',
      //   link: 'https://www.raylink.live/?renderbus',
      //   link: '',
      // },
    ]
    return (
      <RayvisionLinksContainer>
        <span>瑞云旗下其他产品系列：</span>
        {rayvisionLinks.map(({ name, link }, index) => (
          <a key={index} href={link} target='_blank' rel='noopener noreferrer nofollow'>
            {name}
          </a>
        ))}
      </RayvisionLinksContainer>
    )
  }
}

export default RayvisionLinks
