import React from 'react'
import PropTypes from 'prop-types'

class ClickOutside extends React.PureComponent {
  constructor(props) {
    super(props)
    this.wrapperRef = null
    this.handleRef = this.handleRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }
  componentDidMount() {
    const { mouseEvent = 'mousedown', touchEvent = 'touchstart' } = this.props
    document.addEventListener(mouseEvent, this.handleClickOutside)
    document.addEventListener(touchEvent, this.handleClickOutside)
  }
  componentWillUnmount() {
    const { mouseEvent = 'mousedown', touchEvent = 'touchstart' } = this.props
    document.removeEventListener(mouseEvent, this.handleClickOutside)
    document.removeEventListener(touchEvent, this.handleClickOutside)
  }
  handleRef(node) {
    this.wrapperRef = node
  }
  handleClickOutside(event) {
    if (document.documentElement && !this.wrapperRef.contains(event.target)) {
      this.props.onClickOutside(event)
    }
  }
  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, { ref: this.handleRef })}
      </React.Fragment>
    )
  }
}

ClickOutside.propTypes = {
  children: PropTypes.element.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  touchEvent: PropTypes.oneOf(['onTouchStart', 'onTouchEnd']),
  mouseEvent: PropTypes.oneOf(['onClick', 'onMouseDown', 'onMouseUp']),
}

export default ClickOutside
