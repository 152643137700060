import React, { Component } from 'react'
import { navigate } from 'gatsby'
import { CmsContentService } from '../../../service'
import Highlight from '../../highlight'
import {
  SupportInstantSearchResultContainer as QaInstantSearchResultContainer,
  SupportInstantSearchResultItem as QaInstantSearchResultItem,
  SupportInstantClassification as QaInstantClassification,
  SupportInstantResultContent as QaInstantResultContent,
  SupportInstantResultDetail as QaInstantResultDetail,
} from './atoms'

class QaSearchResult extends Component {
  getAnswer(answer) {
    return answer.replace(/(\r\n|__|#|<.*?>|!?\[.*\]\(.*?\)!?)/g, '')
  }
  render() {
    const { result, searchContent } = this.props
    return searchContent ? (
      <QaInstantSearchResultContainer>
        {result.length > 0 &&
          result.map((page, index) => (
            <QaInstantSearchResultItem
              key={index}
              onClick={() =>
                navigate(`/${page.slug}`, {
                  state: {
                    newPage: 1,
                  },
                })
              }
            >
              <QaInstantClassification>
                <p>{page.tags[0] && page.tags[0].name}</p>
              </QaInstantClassification>
              <QaInstantResultContent>
                <Highlight htmlFragment={page.question} keywords={searchContent} />
                <QaInstantResultDetail>
                  {CmsContentService.getAbstract(page.answer.answer) ? (
                    <Highlight
                      htmlFragment={CmsContentService.getAbstract(page.answer.answer)}
                      keywords={searchContent}
                      type='partial'
                    />
                  ) : (
                    this.getAnswer(page.answer.answer)
                  )}
                </QaInstantResultDetail>
              </QaInstantResultContent>
            </QaInstantSearchResultItem>
          ))}
        {result.length === 0 && <div>{`没有 "${searchContent}" 相关文章`}</div>}
      </QaInstantSearchResultContainer>
    ) : null
  }
}
export default QaSearchResult
