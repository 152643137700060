import styled from 'styled-components'
import Flex from '../flex'
import { color, typography, textEllipsis, Media } from '../../theme'

// @see https://github.com/styled-components/styled-components/issues/1546#issuecomment-373281955/
export const BreadcrumbContainer = styled(Flex.withComponent('ul'))`
  width: 750px;
  color: #999;
  ${textEllipsis}
  list-style: none;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  svg {
    margin: 0 12px;
    overflow: visible;
    path {
      stroke: #999;
    }
    ${Media.lessThan(Media.small)} {
      margin: 0 6px;
    }
  }

  li {
    font-size: ${typography.textSmall};
    a {
      border-left: 0px;
      color: #999;
    }
    ::after {
      content: ${props => `"${props.separator}"`};
      padding: 0 8px;
    }
    :last-child::after {
      content: '';
    }
    &:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: ${color.primary};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
