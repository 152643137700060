// use lower case
export const PageViewIndex = {
  index: 'index',
  ysdhnzhd: 'ysdhnzhd',
  filmtv: 'filmtv',
}

export const PageViewMap = {
  [PageViewIndex.index]: { pageName: '国内官网首页', pageUrl: 'https://www.renderbus.com' },
  [PageViewIndex.ysdhnzhd]: {
    pageName: '动画年终活动',
    pageUrl: 'https://www.renderbus.com/ysdhnzhd.html',
  },
  [PageViewIndex.filmtv]: {
    pageName: '影视动画渲染',
    pageUrl: 'https://www.renderbus.com/film-tv-rendering.html',
  },
}
