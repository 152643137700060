import React from 'react'
import styled from 'styled-components'
import { color } from '../../theme/variables'
import transition from '../../theme/transition'

const CloseWrap = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  background-color: transparent;
  path {
    stroke-width: 3;
    stroke-linecap: round;
    stroke: ${color.text};
  }
  #mask {
    opacity: 0;
    stroke: url(#colorTone);
    transition: ${transition(['opacity'])};
  }
  :hover #mask {
    opacity: 1;
  }
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
class Close extends React.PureComponent {
  render() {
    return (
      <CloseWrap className='close-wrap' onClick={this.props.onClick}>
        <svg viewBox='0 0 30 30'>
          <defs>
            <linearGradient id='colorTone'>
              <stop offset='0' stopColor={color.primary} />
              <stop offset='1' stopColor={color.secondary} />
            </linearGradient>
          </defs>
          <path d='M 7,7 L 23,23 M 23,7 L 7,23' />
          <path id='mask' d='M 7,7 L 23,23 M 23,7 L 7,23' />
        </svg>
      </CloseWrap>
    )
  }
}

export default Close
