class API {
  static auth = {
    host: 'https://task.renderbus.com',
    testHost: 'https://task-pre.renderbus.com',
    queryUser: '/api/rendering/user/queryUser',
    logout: '/api/rendering/user/userLogout',
    agentApply: '/api/rendering/user/agent/agentApply',
    downloadHost: 'https://cdl.renderbus.com',
    betaVersion: '/client/desktop/qclient/changelog.php?p=4&os=windows&lang=0',
    windowsVersion: '/client/desktop/qclient/changelog.php?p=0&os=windows&lang=0',
    linuxVersion: '/client/desktop/qclient/changelog.php?p=0&os=linux&lang=0',
    vfxVersion: '/client/desktop/qclient/changelog.php?p=2&os=windows&lang=0', //效果图
    vfxNewVersion: '/client/desktop/qclient/changelog.php?p=6&os=windows&lang=0', // 新版效果图
    getAuthToken: '/api/rendering/user/generateRealNameAuth', // 获取实名认证token
  }
  static event = {
    uploadActivityForm: '/api/rendering/pay/activity/uploadActivityForm',
    getActivityQualification: '/api/rendering/pay/activity/getActivityQualification',
    queryWinningRecord: '/api/rendering/pay/activity/queryWinningRecord ',
    getSupportActivity: '/api/rendering/pay/activity/template/getSupportActivity',
    getUserPrizeCode: '/api/rendering/pay/activity/template/getUserPrizeCode',
    uploadScreenshot: '/api/rendering/user/feedback/uploadScreenshot',
    addFeedback: '/api/rendering/user/feedback/frontUserFeedback',
    queryQuestionnaire: '/api/rendering/user/productResearch/questionnaire',
    submitQuestionnaire: '/api/rendering/user/productResearch/questionnaireResult',
  }
}
export default API
