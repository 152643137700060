import styled, { keyframes } from 'styled-components'

import shadows from '../../theme/shadows'
import { duration } from '../../theme/transition'
import { color, typography } from '../../theme/variables'

const moveIn = keyframes`
  0% {
    top: -5em;
  }
  100% {
    top: 10%;
  }
`
const moveOut = keyframes`
  0% {
    top: 10%;
  }
  100% {
    top: -5em;
  }
`
export const ToastWrap = styled.div`
  position: fixed;
  z-index: 1001;
  top: -5em;
  color: white;
  left: 50%;
  height: 2em;
  padding: 0 1em;
  line-height: 2em;
  touch-action: none;
  border-radius: 5px;
  transform: translateX(-50%);
  font-size: ${typography.text};
  background-image: linear-gradient(to right, ${color.primary}, ${color.secondary});
  box-shadow: ${shadows[3]};
  &.entering {
    animation: ${moveIn} ${duration.standard}ms;
  }
  &.exiting {
    animation: ${moveOut} ${duration.standard}ms;
  }
`
