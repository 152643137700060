import React from 'react'

const SvgProduct05 = props => (
  <svg viewBox='0 0 24 23.31' {...props}>
    <defs>
      <style>
        {'.product-05_svg__cls-1{fill:none;stroke:#13cb75;stroke-miterlimit:10;stroke-width:2px}'}
      </style>
    </defs>
    <g id='product-05_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='product-05_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path className='product-05_svg__cls-1' d='M2.05 6.81l10.59 5.88 9.41-6-9.93-5.5z' />
        <path
          className='product-05_svg__cls-1'
          d='M6.46 9.06l-4.41 2.48 10.59 5.88 9.41-6-4.13-2.33'
        />
        <path
          className='product-05_svg__cls-1'
          d='M6.53 13.75l-4.48 2.52 10.59 5.87 9.41-6-4.05-2.3'
        />
      </g>
    </g>
  </svg>
)

export default SvgProduct05
