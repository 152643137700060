import React from 'react'

const SvgDownload01 = props => (
  <svg id='download_01_svg__OBJECTS' x={0} y={0} viewBox='0 0 46 46' xmlSpace='preserve' {...props}>
    <style>{'.download_01_svg__st1{fill:#fff}'}</style>
    <linearGradient
      id='download_01_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={2.35}
      y1={2203.65}
      x2={43.65}
      y2={2162.35}
      gradientTransform='translate(0 -2160)'
    >
      <stop offset={0.5} stopColor='#13cb75' />
      <stop offset={1} stopColor='#46e0cc' />
    </linearGradient>
    <path
      d='M38 46H8c-4.4 0-8-3.6-8-8V8c0-4.4 3.6-8 8-8h30c4.4 0 8 3.6 8 8v30c0 4.4-3.6 8-8 8z'
      fill='url(#download_01_svg__SVGID_1_)'
    />
    <path
      className='download_01_svg__st1'
      d='M19.8 15l-3.6-6h-4.6l3.6 6zM8 15h4.6L9.4 9.6c-.8.6-1.4 1.6-1.4 2.7V15zM34.2 15l-3.6-6h-4.7l3.7 6zM27 15l-3.6-6h-4.6l3.6 6zM38 15v-2.7c0-1.8-1.5-3.3-3.3-3.3h-1.5l3.6 6H38zM8 17v16.7c0 1.8 1.5 3.3 3.3 3.3h23.3c1.8 0 3.3-1.5 3.3-3.3V17H8zm19.1 10.7L21.3 31c-.7.4-1.5-.1-1.5-.9v-6.7c0-.8.8-1.2 1.5-.9l5.8 3.3c.6.5.6 1.5 0 1.9z'
    />
  </svg>
)

export default SvgDownload01
