import React, { Component, Fragment } from 'react'

import { navigate } from 'gatsby'
import { Divider, Highlight, Breadcrumb } from '../../../components'
// import noResult from '../../../../images/support_nofound.png'

import {
  SupportSearchResultContainer,
  SupportSearchResultItem,
  SupportResultTitle,
  SupportResultAbstract,
  NoResultContainer,
} from './atoms'

class SupportSearchResult extends Component {
  render() {
    const { result, searchContent, currentPage } = this.props
    return result[currentPage - 1] && result[currentPage - 1].length > 0 ? (
      <SupportSearchResultContainer>
        {result[currentPage - 1].map((page, index) => (
          <Fragment key={index}>
            <SupportSearchResultItem onClick={() => navigate(`/${page.supportSlug}`)}>
              <SupportResultTitle>
                <Highlight htmlFragment={page.supportTitle} keywords={searchContent} />
              </SupportResultTitle>
              <SupportResultAbstract>
                <Highlight htmlFragment={page.supportAbstract} keywords={searchContent} />
              </SupportResultAbstract>
              <Breadcrumb separator='>'>
                {page.supportBreadcrumb.map((item, index) => (
                  <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </SupportSearchResultItem>
            <Divider />
          </Fragment>
        ))}
      </SupportSearchResultContainer>
    ) : (
      <NoResultContainer>暂无找到相关文章</NoResultContainer>
    )
  }
}

export default SupportSearchResult
