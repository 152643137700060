import React, { Component } from 'react'
import { CmsPostCard } from '../../../components'

import { NoResultContainer } from './atoms'

class NewsSearchResult extends Component {
  render() {
    const { result, searchContent, currentPage } = this.props
    return result[currentPage - 1] && result[currentPage - 1].length > 0 ? (
      <div>
        {result[currentPage - 1].map((page, index) => (
          <CmsPostCard
            highlight={searchContent}
            key={index}
            title={page.newsTitle}
            content={page.newsContent}
            tinyMceContent={page.newsTinyMceConent}
            slug={page.newsSlug}
            date={page.newsDate}
            category={page.newsCategory}
          />
        ))}
      </div>
    ) : (
      <NoResultContainer>暂无找到相关文章</NoResultContainer>
    )
  }
}

export default NewsSearchResult
