import styled from 'styled-components'
import Flex from '../flex'
import { H2, H1 } from '../atoms'
import {
  typography,
  color,
  multiLineTextEllipsis,
  textEllipsis,
  Media,
  transition,
  spacing,
} from '../../theme'
import { Link } from 'gatsby'

const greenText = '瑞云热点'

export const SideBarTitle = styled(H2)`
  padding: 0 ${spacing.small};
  margin: 1rem 0;
  font-size: ${typography.h4};
  color: black;
  &.news {
    position: relative;
    font-size: 20px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: #13cb75;
      width: 4px;
      height: 24px;
    }
  }
`
export const CmsPostContainer = styled.div`
  color: #444;
  max-width: 750px;
  &.news,
  &.share {
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 18px;
      font-weight: bold;
      strong {
        font-weight: bold;
      }
    }
    h3 {
      font-size: 16px;
    }
  }
  a {
    color: ${color.primary};
  }
  div,
  p,
  span {
    line-height: 2;
  }
  p,
  span,
  img,
  iframe {
    margin: ${spacing.base} 0;
  }
  img,
  video,
  iframe {
    display: block;
    max-width: 100%;
  }
  table {
    margin: 1em 0;
    width: 100%;
    overflow: hidden;
    border: 0;
  }
  tr {
    border: 1px solid ${color.text};
  }
  td {
    word-wrap: break-word;
    max-width: 7em;
    border: 1px solid ${color.highlight};
    padding: 5px;
  }
  th {
    border: 1px solid ${color.primary};
    background-color: ${color.secondary};
    color: #fff;
    padding: 1em;
  }
  ul,
  ol {
    padding-left: 20px;
  }
  *.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  *.bold {
    font-weight: bold;
  }
  *.img-source {
    text-align: center;
    color: ${color.prompt};
    font-size: ${typography.textSmall};
    margin: 0;
  }
  pre,
  code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  &.news img,
  &.share img {
    margin: ${spacing.base} auto;
  }
  &.share {
    img {
      height: 100%;
    }
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: auto;
      height: auto;
    }
  }
`
export const CmsPostTitle = styled(H1)`
  width: auto;
  &.share {
    width: 750px;
  }
  margin-top: 20px;
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
  ${Media.lessThan(Media.small)} {
    &.share {
      width: auto;
    }
  }
`
export const CmsPostMsg = styled(Flex)`
  justify-content: flex-start;
  color: ${color.prompt};
  margin: 10px 0 20px;
  font-size: ${typography.textSmall};
  ${textEllipsis}
  span {
    margin-right: ${spacing.small};
  }
  svg {
    margin-right: 5px;
  }
  div {
    margin-right: ${spacing.base};
  }
  a {
    margin-right: 20px;
    color: ${color.primary};
    :hover {
      color: #10b367;
    }
  }
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    gap: 4px;
    div {
      margin-right: ${spacing.small};
    }
    a {
      margin-right: 10px;
    }
  }
`

export const CmsLinkToPost = styled(Link)`
  color: #333;
  font-size: ${typography.textSmall};
  margin-bottom: ${spacing.small};
  width: 370px;
  height: 70px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 15px 10px;
  cursor: pointer;
  .share-title {
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 345px;
    overflow: hidden;
    display: inline-block;
  }
  .pre-btn,
  .next-btn {
    display: flex;
    align-items: center;
    line-height: 14px;
    margin-bottom: 8px;
    & > span {
      margin: 0 8px;
    }
  }
  &:hover {
    color: ${color.primary};
    .arrow {
      path {
        stroke: ${color.primary};
      }
    }
    .share-title {
      color: ${color.primary};
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 49%;
    height: 70px;
    padding: 8px 7px;
    font-size: 10px;
    .pre-btn,
    .next-btn {
      line-height: 10px;
      margin-bottom: 4px;
    }
    .share-title {
      width: 100%;
      white-space: pre-wrap;
      ${multiLineTextEllipsis(2)}
    }
  }
`
export const PostCardContainer = styled(Flex)`
  position: relative;
  padding: 1rem;
  margin: 0.5rem 0;
  justify-content: flex-start;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: ${transition('box-shadow')};
  :hover {
    background: #ffffff;
    box-shadow: 0px 0px 27px 3px rgba(153, 153, 153, 0.2);
    border-radius: 10px;
    .post-card-title > a {
      color: #13cb75;
    }
  }
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    background: #ffffff;
    box-shadow: 0px 0px 27px 3px rgba(153, 153, 153, 0.2);
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 0;
    overflow: hidden;
  }
`

export const PostCardImg = styled.div`
  img {
    display: block;
    width: 240px;
    height: 160px;
    object-fit: cover;
    overflow: hidden;
  }

  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    img {
      width: 100%;
      height: 223px;
    }
  }
`
export const PostCardContent = styled(Flex)`
  max-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin: 1.5rem;
  ${Media.lessThan(Media.small)} {
    // margin: 1rem 0;
    padding: 20px 15px;
    margin: 0;
  }
`
export const PostCardTitle = styled.div`
  color: ${color.panel};
  a {
    color: ${color.panel};
  }
  p {
    margin: 0;
  }
  strong {
    color: ${color.primary};
  }
  em {
    color: ${color.primary};
    font-style: normal;
  }
  font-size: ${typography.h4};
`
export const PostCardIntro = styled.div`
  font-size: ${typography.textSmall};
  color: ${color.prompt};
  margin: 1rem 0;
  ${multiLineTextEllipsis(2)}
  p {
    margin: 0;
  }
  strong {
    color: ${color.primary};
  }
  em {
    color: ${color.primary};
    font-style: normal;
  }
  pre,
  code {
    overflow: auto;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`
export const PostCardDate = styled.span`
  min-width: 90px;
  display: inline-block;
  svg {
    margin-right: 5px;
  }
`
export const PostCardMsg = styled(Flex)`
  max-width: 100%;
  justify-content: flex-start;
  font-size: ${typography.textSmall};
  color: ${color.prompt};
  span {
    margin-right: ${spacing.base};
    ${textEllipsis}
  }
  a {
    margin-right: 15px;
    color: #13cb75;
    :hover {
      color: #10b367;
    }
  }
  ${Media.lessThan(Media.small)} {
    a {
      white-space: nowrap;
      margin-right: 5px;
    }
  }
`
export const PostCardCategory = styled.div`
  position: absolute;
  top: 1.55rem;
  left: 1.25rem;
  padding: 5px 10px;
  background: ${p => (p.category === greenText ? color.primary : '#373737')};
  color: white;
  font-size: ${typography.textThin};
`

export const PostTagsWrapper = styled.div`
  display: inline-flex;
  ${Media.lessThan(Media.small)} {
    max-width: 200px;
    a:last-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`
