class Media {
  static xsmall = Symbol('xsmall')
  static small = Symbol('small')
  static medium = Symbol('medium')
  static large = Symbol('large')
  static xlarge = Symbol('xlarge')
  static xxlarge = Symbol('xxlarge')
  static xxxlarge = Symbol('xxxlarge')
  static sizeMap = new Map([
    [this.xsmall, { min: 0, max: 599 }],
    [this.small, { min: 600, max: 779 }],
    [this.medium, { min: 780, max: 979 }],
    [this.large, { min: 980, max: 1279 }],
    [this.xlarge, { min: 1280, max: 1339 }],
    [this.xxlarge, { min: 1440, max: 1920 }],
    [this.xxxlarge, { min: 1940, max: 2560 }],
  ])
  static between(smallKey, largeKey) {
    if (!this.sizeMap.has(smallKey) || !this.sizeMap.has(largeKey)) {
      throw new Error('Media does not have these sizes')
    }
    const smallSize = this.sizeMap.get(smallKey).min
    const largeSize = this.sizeMap.get(largeKey).max
    return `@media (min-width: ${smallSize}px) and (max-width: ${largeSize}px)`
  }
  static greaterThan(key) {
    if (!this.sizeMap.has(key)) {
      throw new Error('Media does not have this size')
    }
    return `@media (min-width: ${this.sizeMap.get(key).min}px)`
  }
  static lessThan(key) {
    if (!this.sizeMap.has(key)) {
      throw new Error('Media does not have this size')
    }
    return `@media (max-width: ${this.sizeMap.get(key).min - 1}px)`
  }
  static size(key) {
    if (!this.sizeMap.has(key)) {
      throw new Error('Media does not have this size')
    }
    return this.between(key, key)
  }
}

export default Media
