import * as React from 'react'
const SVGComponent = props => (
  <svg version='1.1' x='0px' y='0px' viewBox='0 0 24 24' width='24px' height='24px' {...props}>
    <g>
      <g>
        <path
          fill='#FFFFFF'
          d='M10.4,24H4.3c-1.2,0-2.1-0.9-2.1-2.1V2.1C2.2,0.9,3.1,0,4.3,0h15.4c1.2,0,2.1,0.9,2.1,2.1v11.1
        c0,0.6-0.4,1-1,1s-1-0.4-1-1V2.1c0-0.1,0-0.1-0.1-0.1H4.3C4.2,2,4.2,2,4.2,2.1v19.8c0,0.1,0,0.1,0.1,0.1h6.1c0.6,0,1,0.4,1,1
        S10.9,24,10.4,24z'
        />
      </g>
      <g>
        <path
          fill='#FFFFFF'
          d='M15.8,6.4H8.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h7.7c0.6,0,1,0.4,1,1S16.4,6.4,15.8,6.4z'
        />
      </g>
      <g>
        <path
          fill='#FFFFFF'
          d='M15.8,10.8H8.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h7.7c0.6,0,1,0.4,1,1S16.4,10.8,15.8,10.8z'
        />
      </g>
      <g>
        <path
          fill='#FFFFFF'
          d='M11.5,15.2H8.1c-0.6,0-1-0.4-1-1s0.4-1,1-1h3.3c0.6,0,1,0.4,1,1S12,15.2,11.5,15.2z'
        />
      </g>
      <g>
        <path
          fill='#13CB75'
          d='M15.3,24h-2.2c-0.6,0-1-0.4-1-1v-2.2c0-0.3,0.1-0.5,0.3-0.7l5.5-5.5c0.4-0.4,1-0.4,1.4,0l2.2,2.2
        c0.4,0.4,0.4,1,0,1.4L16,23.7C15.8,23.9,15.6,24,15.3,24z M14.1,22h0.8l4.5-4.5l-0.8-0.8l-4.5,4.5V22z'
        />
      </g>
    </g>
  </svg>
)
export default SVGComponent
