import { Link } from 'gatsby'
import React, { Fragment } from 'react'

import { Icon, LoadingIcon, WebpImg } from '..'
import { CmsContentService } from '../../service'
import { getDateFormatYMDHMS } from '../../utils'

import { ElasticSearchResultContainer, LoadingContainer } from './atoms'
import {
  PostCardContainer,
  PostCardImg,
  PostCardContent,
  PostCardTitle,
  PostCardIntro,
  PostCardDate,
  PostCardMsg,
} from '../cms/atoms'
import { IconType } from '../icon'

export default React.memo(function ElasticSearchResult({
  result = [],
  searchContent = '',
  loading = false,
  type = 'news',
}) {
  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIcon />
      </LoadingContainer>
    )
  }
  return result && result.length > 0 ? (
    <ElasticSearchResultContainer>
      {result.map((page, index) => (
        <Fragment key={index}>
          <PostCardContainer>
            <PostCardImg>
              <WebpImg
                src={CmsContentService.getCoverImg(page.data.content)}
                alt={page.data.title}
                className='cover-webp'
              />
            </PostCardImg>
            <PostCardContent>
              <PostCardTitle
                dangerouslySetInnerHTML={{
                  __html: page.highlight ? page.highlight.title : page.data.title,
                }}
                as={Link}
                to={`/${page.data.slug}/`}
                target='_blank'
              ></PostCardTitle>
              <PostCardIntro
                dangerouslySetInnerHTML={{
                  __html: page.highlight ? page.highlight.plainContent : page.data.plainContent,
                }}
              ></PostCardIntro>
              <PostCardMsg>
                <PostCardDate>
                  <Icon type={IconType.Clock} />
                  {getDateFormatYMDHMS(page.data.legacyDate || page.data.createdAt)}
                </PostCardDate>
                {page.data.tags &&
                  page.data.tags.map(({ name, slug }, index) => (
                    <Link
                      key={index}
                      to={`/${slug}/`}
                      style={{ color: '#999', marginRight: '5px' }}
                    >
                      {name}
                    </Link>
                  ))}
              </PostCardMsg>
            </PostCardContent>
          </PostCardContainer>
        </Fragment>
      ))}
    </ElasticSearchResultContainer>
  ) : (
    <LoadingContainer>暂无相关内容</LoadingContainer>
  )
})
