class LocationService {
  static getLocationSearchParams() {
    let searchMap = new Map()
    if (typeof window !== 'undefined') {
      const search = window.location.search
      if (search) {
        const params = search.slice(1).split('&')
        params.forEach(param => {
          const item = param.match(/(.*)=(.*)/)
          if (item) {
            searchMap.set(item[1], decodeURIComponent(item[2]))
          }
        })
      }
    }
    return searchMap
  }

  static updateLocationSearchString(newParams, pathName) {
    if (typeof window !== 'undefined') {
      let searchString = '?'
      const pathPrefixPattern = /news\/|share\/|support-new\/|qa\//
      const searchParams = LocationService.getLocationSearchParams()
      Object.keys(newParams).forEach(param => {
        searchParams.set(param, newParams[param])
      })
      searchParams.forEach((value, key) => {
        searchString += `${key}=${encodeURIComponent(value)}&`
      })
      const newSearchString = searchString.slice(0, searchString.length - 1)
      const newPathName = (pathName || window.location.pathname).replace(pathPrefixPattern, '')
      return `${newPathName}${newSearchString}`
    }
  }

  static updatePageNum(num) {
    if (typeof window !== 'undefined') {
      const pagePattern = /page-(\d+)/
      const pathPrefixPattern = /news\/|share\/|support-new\//
      const pathname = window.location.pathname.replace(pathPrefixPattern, '')
      const pageNum = encodeURIComponent(`page-${num}`)
      if (pathname.match(pagePattern)) {
        if (+num === 1) {
          return pathname.replace(pagePattern, '')
        }
        return pathname.replace(pagePattern, pageNum)
      }
      return `${pathname}/${pageNum}/`
    }
  }
  static getPageNum() {
    if (typeof window !== 'undefined') {
      const pagePattern = /page-(\d+)/
      const pathname = window.location.pathname
      if (pathname.match(pagePattern)) {
        return pathname.match(pagePattern)[1]
      }
      return 1
    }
  }
}

export default LocationService
