import styled from 'styled-components'
import { lighten } from 'polished'
import { spacing, color, typography, multiLineTextEllipsis, Media } from '../../../theme'
import Flex from '../../flex'

export const SupportInstantSearchResultContainer = styled.div`
  width: 400px;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(178, 127, 114, 0.1);
  border: 1px solid ${color.primary};
  border-radius: 5px;
  z-index: 1;
  ::before {
    content: '';
    position: absolute;
    top: -30px;
    right: 10px;
    border: 15px solid transparent;
    border-bottom-color: ${color.primary};
  }
  ::after {
    content: '';
    position: absolute;
    top: -29px;
    right: 10px;
    border: 15px solid transparent;
    border-bottom-color: white;
  }
  ${Media.lessThan(Media.small)} {
    width: 300px;
  }
`

export const SupportInstantSearchResultItem = styled(Flex)`
  border-bottom: 1px solid ${color.primary};
  color: ${lighten(0.2, color.panel)};
  font-weight: bold;
  cursor: pointer;
  :first-of-type {
    margin-top: ${spacing.small};
  }
  :last-of-type {
    border-bottom: none;
  }
`
export const SupportInstantResultContent = styled.div`
  padding: ${spacing.small};
  text-align: left;
  p {
    width: 280px;
  }
  :hover {
    background-color: #b9f6ca;
  }
  strong {
    color: ${color.primary};
    background-color: #b9f6ca;
  }
  ${Media.lessThan(Media.small)} {
    padding: 5px;
    p {
      width: 200px;
    }
  }
`
export const SupportInstantClassification = styled(Flex)`
  flex-wrap: wrap;
  width: 100px;
  p {
    padding-right: ${spacing.small};
    border-right: 1px solid ${color.primary};
    text-align: right;
    width: 100px;
    font-size: ${typography.textSmall};
  }
`
export const SupportInstantResultDetail = styled.div`
  color: ${color.prompt};
  font-weight: normal;
  margin-top: 5px;
  font-size: ${typography.textSmall};
  height: 38px;
  ${multiLineTextEllipsis(2)};
`

export const SupportSearchResultContainer = styled.div`
  width: 100%;
  padding: ${spacing.small} 0;
  cursor: pointer;
`
export const SupportSearchResultItem = styled(Flex)`
  padding: ${spacing.small} 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  & strong {
    color: ${color.highlight};
  }
`
export const SupportResultTitle = styled.h3`
  color: ${color.panel};
  margin-bottom: ${spacing.small};
  font-size: 20px;
`
export const SupportResultAbstract = styled.div`
  margin-bottom: ${spacing.small};
  color: ${lighten(0.2, color.panel)};
  text-align: left;
  height: 45px;
  ${multiLineTextEllipsis(2)};
  font-size: ${typography.text};
`
export const NoResultContainer = styled.div`
  width: 100%;
  padding: ${spacing.small} 0;
  text-align: center;
`

export const QaNoResultContainer = styled(NoResultContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`
export const PostCardDate = styled.span``

export const EmptyImg = styled.img`
  margin: 20px 0;
`

export const EmptyText = styled.span`
  color: ${color.prompt};
`
