import plupload from 'plupload'
import axios from 'axios'

class Plupload {
  constructor({
    browseButtonId = '',
    dragElementId = '',
    containerId = '',
    multiSelection = false,
    uploadAfterAdd = false,
    uploadURL = 'http://oss.aliyuncs.com',
    uploadSignatureURL,
    filters = {
      // mime_types: mime_types : "video/*,application/zip"
      mime_types: [
        // { title: "Video files", extensions: "mp4,mov,wmv,flv,avi,avchd,webm,mkv" },
        // { title: "Image files", extensions: "jpg,gif,png" },
        // { title: "Zip files", extensions: "zip" },
      ],
      max_file_size: '1gb', //1gb、1mb
      prevent_duplicates: true, //不允许选取重复文件
    },
  }) {
    this.uploader = null
    this.uploadSignature = null
    this.filesAddedCallback = () => {}
    this.filesUploadingHandler = () => {}
    this.filesUploadedCallback = () => {}
    this.filesUploadErrorHandler = () => {}
    this.selectedFileCount = 0
    this.multi_selection = multiSelection || false
    this.uploadSignatureURL = uploadSignatureURL
    this.baseUploadSignatureURL = uploadSignatureURL
    this.uploadAfterAdd = uploadAfterAdd || false
    this.options = {
      browse_button: browseButtonId,
      drop_element: dragElementId,
      multi_selection: this.multi_selection,
      container: containerId,
      url: uploadURL || 'http://oss.aliyuncs.com',
      filters: filters || {},
    }
    Reflect.ownKeys(this.options).forEach(key => {
      if (this.options[key] === '' || this.options[key] === undefined)
        Reflect.deleteProperty(this.options, key)
    })
  }
  setUploadSignatureURL(value) {
    this.uploadSignatureURL = value
    this.baseUploadSignatureURL = value
  }
  setFilesAddedCallback(callback = (up, file) => {}) {
    this.filesAddedCallback = callback
    return this
  }
  setFilesUploadingHandler(handler = (up, file) => {}) {
    this.filesUploadingHandler = handler
    return this
  }
  setFilesUploadedCallback(callback = (up, file, info) => {}) {
    this.filesUploadedCallback = callback
    return this
  }
  setFilesUploadErrorHandler(handler = (up, err) => {}) {
    this.filesUploadErrorHandler = handler
    return this
  }

  async getUploadSignature() {
    const { status, data } = await axios.get(this.uploadSignatureURL)
    if (status === 200) {
      this.uploadSignature = {
        host: data['host'],
        policyBase64: data['policy'],
        accessid: data['accessid'],
        signature: data['signature'],
        expire: parseInt(data['expire']),
        callbackbody: data['callback'],
        key: data['dir'],
      }
    } else {
      this.uploadSignature = null
    }
  }

  setMultipartParam(params, fileName) {
    const _params =
      params ||
      (this.uploadSignature && {
        key: `${this.uploadSignature.key}${fileName}`,
        policy: this.uploadSignature.policyBase64,
        OSSAccessKeyId: this.uploadSignature.accessid,
        success_action_status: '200', //让服务端返回200,不然，默认会返回204
        callback: this.uploadSignature.callbackbody,
        signature: this.uploadSignature.signature,
      })
    const _this = this
    this.uploader.setOption('url', _this.uploadSignature.host)
    this.uploader.setOption('multipart_params', _params)
  }

  init() {
    const _this = this
    this.uploader = new plupload.Uploader({
      runtimes: 'html5,flash,silverlight,html4',
      ..._this.options,
      init: {
        FilesAdded: function(up, files) {
          !_this.multi_selection && (files = [files.pop()])
          plupload.each(files, async function(file) {
            _this.filesAddedCallback(up, file)
            if (_this.multi_selection) {
              _this.uploadSignatureURL += _this.uploadSignatureURL.includes('/?')
                ? `&file${_this.selectedFileCount}=${file.name}`
                : `/?file${_this.selectedFileCount}=${file.name}`
              _this.selectedFileCount++
            } else {
              _this.uploadSignatureURL = _this.baseUploadSignatureURL
              _this.uploadSignatureURL += _this.baseUploadSignatureURL.includes('/?')
                ? `&file${_this.selectedFileCount}=${file.name}`
                : `/?file${_this.selectedFileCount}=${file.name}`
            }
            if (_this.uploadAfterAdd) {
              await _this.getUploadSignature()
              _this.setMultipartParam(undefined, file.name)
              _this.start()
            }
          })
        },
        BeforeUpload: async function(up, file) {
          if (!_this.uploadAfterAdd) {
            await _this.getUploadSignature(undefined, file.name)
            _this.setMultipartParam()
          }
        },
        UploadProgress: function(up, file) {
          _this.filesUploadingHandler(up, file)
        },
        FileUploaded: function(up, file, info) {
          _this.filesUploadedCallback(up, file, info)
        },
        Error: function(up, err) {
          _this.filesUploadErrorHandler(up, err)
        },
      },
    })
    _this.uploader.init()
  }

  start() {
    this.uploader && this.uploader.start()
  }

  refresh() {
    this.uploader && this.uploader.refresh()
  }

  destroy() {
    this.uploader && this.uploader.destroy()
  }

  stop() {
    this.uploader && this.uploader.stop()
  }
}

export default Plupload
