import React from 'react'
import PropTypes from 'prop-types'
import { range } from '../../utils'
import FadeSwiperViews from './fade-swiper-views'
import autoPlay from './auto-play.js'
import { FadeNavWrap, FadeNav, Container } from './atoms'
import { ArrowIcon } from '../swiper/atoms'
const AutoPlaySwipeableViews = autoPlay(FadeSwiperViews)

class FadeSwiper extends React.PureComponent {
  state = { index: 0, auto: true }
  handleChangeIndex = index => {
    this.setState({ index })
  }
  handleFocus = () => {
    this.setState({ auto: false })
  }
  handleBlur = () => {
    this.setState({ auto: true })
  }
  handleNextClick = () => {
    const length = this.props.children.length
    this.setState(prev => ({
      index: prev.index + 1 === length ? 0 : prev.index + 1,
    }))
  }
  handlePrevClick = () => {
    const length = this.props.children.length
    this.setState(prev => ({
      index: prev.index === 0 ? length - 1 : prev.index - 1,
    }))
  }
  render() {
    const { index, auto } = this.state
    const { children, navBottom, swiperHeight, interval = 7000, ...rest } = this.props
    return (
      <Container
        {...rest}
        height={swiperHeight}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        <AutoPlaySwipeableViews
          index={index}
          interval={interval}
          autoplay={auto}
          enableMouseEvents
          onChangeIndex={this.handleChangeIndex}
        >
          {children}
        </AutoPlaySwipeableViews>
        <FadeNavWrap bottom={navBottom}>
          {range(children.length).map(i => (
            <FadeNav
              interval={interval}
              id='fadeNavItem'
              key={i}
              active={i === index}
              onMouseEnter={this.handleChangeIndex.bind(this, i)}
            ></FadeNav>
          ))}
        </FadeNavWrap>
        <ArrowIcon
          onClick={this.handlePrevClick}
          style={{ left: 50, transform: 'rotate(180deg)' }}
        />
        <ArrowIcon onClick={this.handleNextClick} style={{ right: 50 }} />
      </Container>
    )
  }
}

FadeSwiper.propTypes = {
  swiperHeight: PropTypes.string,
}
FadeSwiper.defaultProps = {
  swiperHeight: '100vh',
}

export default FadeSwiper
