import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Media, typography } from '../theme'
import { H1, Flex, Button } from './index'
import NotFound from '../images/404.png'

const NotFoundContainer = styled(Flex)`
  position: relative;
  background-color: #222;
`
const NotFoundImg = styled.img`
  margin: 190px 0 50px;
  max-width: 1200px;
  width: 100%;
`
const NotFoundContent = styled.div`
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  h1 {
    font-size: 30px;
    font-weight: 500;
    color: #2ad788;
  }
  ${Media.lessThan(Media.small)} {
    bottom: 12%;
    h1 {
      font-size: ${typography.h4};
    }
  }
`

class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <NotFoundContainer>
        <NotFoundImg src={NotFound} alt='404' />
        <NotFoundContent>
          <H1>哎呦，页面去外太空了....</H1>
          <Link to='/'>
            <Button variant='outlined' color='primary'>
              返回首页
            </Button>
          </Link>
        </NotFoundContent>
      </NotFoundContainer>
    )
  }
}

export default NotFoundPage
