import { useEffect } from 'react'

/**
 * @description use like below
 * import React, { useRef } from 'react'
 * const Wrap = () => {
 *   const ref = useRef()
 *   useClickOutside(ref, () => callback)
 *   return <Element ref={ref} />
 * }
 */
export function useClickOutside(ref, handler, events = ['mousedown', 'touchstart']) {
  useEffect(() => {
    const handleClickOutside = event =>
      ref.current && !ref.current.contains(event.target) && handler()
    for (const event of events) {
      document.addEventListener(event, handleClickOutside)
    }
    return () => {
      for (const event of events) {
        document.removeEventListener(event, handleClickOutside)
      }
    }
  }, [events, handler, ref])
}
