import React from 'react'

import Partner from './partner'
import { Container } from './atoms'
import PostCards from './post-cards'
import FriendLinks from './friend-links'
import RayvisionLinks from './rayvision-links'

import Wrapper from '../wrapper'
import { Divider } from '../atoms'

class Footer extends React.PureComponent {
  render() {
    const { info, friendLinks, className, marginBottom } = this.props
    return (
      <Container className={className} marginBottom={marginBottom}>
        <Wrapper>
          <PostCards info={info} />
          <RayvisionLinks />
          {friendLinks && <FriendLinks friendLinks={friendLinks} />}
          <Divider style={{ backgroundColor: '#333333' }} />
          <Partner />
        </Wrapper>
      </Container>
    )
  }
}

export default Footer
